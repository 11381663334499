import * as axaPackage from '@axa-fr/oidc-client/package.json';
import { OidcProvider } from '@axa-fr/react-oidc';
import * as Sentry from '@sentry/react';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import CookieConsent from './components/CookieConsent.tsx';
import { PageLoader } from './components/PageLoader.tsx';
import { ReloadComponent } from './components/ReloadComponent.tsx';
import './i18n.ts';
import './index.css';
import { Router } from './Router.tsx';
import { ThemeSwitcher } from './Theme.tsx';

if (!import.meta.env.DEV) {
  Sentry.init({
    enabled: import.meta.env.VITE_ENV !== 'stage',
    dsn: 'https://daff8c4a119f1030ca2c1cd9638fc6cc@o255832.ingest.us.sentry.io/4506970553319424',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/studio\.lizzen\.de/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// https://www.npmjs.com/package/@axa-fr/react-oidc
// This configuration use hybrid mode
// ServiceWorker are used if available (more secure) else tokens are given to the client
// You need to give inside your code the "access_token" when using fetch

const configuration = {
  client_id: import.meta.env.VITE_ENV === 'stage' ? 'lizzen-staging' : 'lizzen',
  redirect_uri: window.location.origin + '/authentication/callback',
  silent_redirect_uri: window.location.origin + '/authentication/silent-callback',
  scope: 'openid profile email ecapi offline_access',
  authority:
    import.meta.env.VITE_ENV === 'stage'
      ? 'https://lizzen-studio-login.cachena.entrecode.de/oidc'
      : 'https://login.lizzen.de/oidc',
  service_worker_relative_url: `/OidcServiceWorker.js?v=${axaPackage.version}`,
  service_worker_only: false,
};

createRoot(document.getElementById('root')!).render(
  <OidcProvider
    configuration={configuration}
    loadingComponent={PageLoader}
    authenticatingComponent={PageLoader}
    callbackSuccessComponent={PageLoader}
    sessionLostComponent={ReloadComponent}
  >
    <ThemeSwitcher>
      <Suspense fallback={<PageLoader />}>
        <Router />
        {!import.meta.env.DEV && <CookieConsent />}
        <Toaster
          position="bottom-center"
          toastOptions={{
            className: '',
            style: {
              color: 'oklch(0.861397 0.007806 171.365)',
              background: '#140f0f',
            },
            success: {
              style: {
                background: 'oklch(0.306985 0.039032 171.365)',
              },
              iconTheme: {
                primary: 'oklch(0.188409 0.00829 17.9116)',
                secondary: 'oklch(0.861397 0.007806 171.365)',
              },
            },
            error: {
              style: {
                background: 'oklch(0.7176 0.221 22.18)',
              },
            },
          }}
        />
      </Suspense>
    </ThemeSwitcher>
  </OidcProvider>,
);
