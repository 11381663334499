import { PageLoaderWithNav } from '@/components/PageLoaderWithNav';
import { useFdk } from '@/fdk';
import { Layout } from '@/Layout';
import { useParams } from 'react-router-dom';
import StationCard from './StationCard';

export function GuideList() {
  const { guideID } = useParams();

  const { data: tour, isLoading } = useFdk({
    action: 'getEntry',
    model: 'guide_tours',
    entryID: guideID,
  });

  const { data: stationList, isLoading: isLoadingStations } = useFdk(
    tour
      ? {
          action: 'entryList',
          model: 'guide_tour_stations',
          options: {
            id: tour.stations,
          },
        }
      : null,
  );

  if (isLoading || isLoadingStations) {
    return <PageLoaderWithNav />;
  }

  return (
    <Layout>
      <section className="card col-span-12 bg-base-100 shadow-sm xl:col-span-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {stationList?.items?.map((station) => (
          <StationCard key={station.id} station={station} tour={tour} />
        ))}
      </section>
    </Layout>
  );
}
