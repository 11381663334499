import { usePlainFdk } from '@/fdk';
import useSWR from 'swr';

export const fetcher = (url) => fetch(url).then((res) => res.json());

export function useStudios(page = 1, count = 15, title?: string, sort?: { id: string; desc: boolean }): any {
  const fdk = usePlainFdk();

  const swr = useSWR(
    [page, count, title, sort],
    async () => {
      const url = new URL(`https://datamanager${import.meta.env.VITE_ENV === 'stage' ? '.cachena' : ''}.entrecode.de/`);
      url.searchParams.set('_list', String(true));
      url.searchParams.set('page', String(page));
      url.searchParams.set('_count', String(count));
      url.searchParams.set('template', 'a761c001-9225-44ee-8c57-141ef92693b5');

      const options: any = {
        page,
        _count: count,
        template: 'a761c001-9225-44ee-8c57-141ef92693b5',
      };

      if (title) {
        options['title~'] = title;
      }

      if (sort) {
        options.sort = `${sort.desc ? '-' : ''}${sort.id}`;
      }

      return fdk.dmList(options);
    },
    { revalidateOnFocus: false },
  );

  return swr;
}
