import ChangeLanguageModal from '@/components/Editor/ChangeLanguageModal';
import { ChapterCopyModal } from '@/components/Editor/ChapterCopyModal';
import { ChapterEditModal } from '@/components/Editor/ChapterEditModal';
import ChapterErrorModal from '@/components/Editor/ChapterErrorModal';
import ChapterGenerateModal from '@/components/Editor/ChapterGenerateModal';
import ChapterGeneratingModal from '@/components/Editor/ChapterGeneratingModal';
import { ChapterSplitPasteTextModal } from '@/components/Editor/ChapterSplitPasteTextModal';
import { ChapterTranslateModal } from '@/components/Editor/ChapterTranslateModal';
import GeneratingFailedModal from '@/components/Editor/GeneratingFailedModal';
import { AddSection, Section } from '@/components/Editor/Section';
import SectionTranslateModal from '@/components/Editor/SectionTranslateModal';
import SpeechToTextModal from '@/components/Editor/SpeechToTextModal';
import Loader from '@/components/Loader';
import { SideNavHistory } from '@/components/SideNavHistory';
import { TopNav } from '@/components/TopNav';
import VoiceSelect from '@/components/VoiceSelect';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { Skeleton } from '@/components/ui/skeleton';
import { useFdk, usePlainFdk } from '@/fdk';
import useDebounce from '@/hooks/useDebounce';
import { publish } from '@/lib/events';
import { getUsedLocalVoices } from '@/lib/utils';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { DialogClose } from '@radix-ui/react-dialog';
import { t } from 'i18next';
import { produce } from 'immer';
import { lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { TrackContext } from '../ChapterDetail';

const Waveform = lazy(() => import('@/components/Editor/Waveform'));
const VariableEditorModal = lazy(() => import('@/components/Editor/VariableEditorModal'));

export type SectionType = {
  _prev?: string;
  modified?: number;
  title: string;
  type: 'text' | 'file' | 'speech';
  pause: number;
  content: string;
  voiceID?: string;
  timestamp?: number;
  generating?: boolean;
  jobID?: string;
};
export type Content = {
  voiceID?: string;
  sections: SectionType[];
};

type ValidationError = {
  type: string;
  index: number;
};

declare interface ChapterEntry {
  content: Content;
  state: string;
  modified: string;
}
const css = `
    #crisp-chatbox .cc-nsge {
      bottom: 90px !important;
    }
    `;

export default function StationContentForm({ projectID, chapterID }) {
  const { data: chapter, mutate: mutateChapter } = useFdk(
    {
      model: 'chapter',
      action: 'getEntry',
      entryID: chapterID,
    },
    {
      revalidateOnFocus: false,
    },
  );

  const { data: project } = useFdk({
    model: 'project',
    action: 'getEntry',
    entryID: projectID,
  });

  if (!chapter || !project) {
    return <SkeletonLayout />;
  }

  return (
    <>
      <style>{css}</style>
      <Form chapter={chapter} mutateChapter={mutateChapter} project={project} />
    </>
  );
}

const Form = ({ chapter, project, mutateChapter }) => {
  const { shortID } = useParams();
  const fdk = usePlainFdk();
  const saveAttempts = useRef(0);

  const { data: parent } = useFdk({
    model: 'chapter',
    action: 'getEntry',
    entryID: chapter.parent,
  });

  const decoratedChapter = useMemo(
    () =>
      produce(chapter as ChapterEntry, (draft) => {
        draft.content.sections.forEach((section) => {
          section._prev = section.content;
        });
      }),
    [chapter],
  );
  const methods = useForm({
    defaultValues: {
      chapter: decoratedChapter,
      lastPasted: {
        paragraphs: null,
        newContent: null,
        oldContent: null,
      },
    },
  });
  const { watch, setValue } = methods;
  const { mutate } = useFdk({
    model: 'chapter',
    action: 'getEntry',
    entryID: chapter.id,
  });

  const editEntry = useCallback(
    async (payload: any) => {
      try {
        const res = await fdk.model('chapter').editEntrySafe(chapter.id as string, {
          ...payload,
          _modified: watch('chapter.modified'),
        });
        setValue('chapter.modified', res.modified as any);
        saveAttempts.current = 0;
      } catch (error: any) {
        if (error.message.includes('Somebody else changed')) {
          console.log('Somebody else changed the ressource');
          await mutate();
          if (saveAttempts.current < 5) {
            saveAttempts.current++;
            await editEntry(payload);
          }
        }
      }
    },
    [fdk, watch],
  );

  const save = useCallback(async () => {
    editEntry({
      state: 'draft',
      content: {
        ...watch('chapter.content'),
        sections: watch('chapter.content.sections'),
      },
    });
  }, [watch, fdk]);

  useDebounce(JSON.stringify(watch(`chapter.content`)), 1000, (e) => {
    save();
  });

  const [deleteSection, setDeleteSection] = useState<any>(null);
  const {
    fields,
    remove: removeSection,
    move,
    append,
  } = useFieldArray({
    keyName: 'id',
    control: methods.control,
    name: 'chapter.content.sections',
  });

  const remove = (index) => {
    setDeleteSection(index);
  };

  const [sm, setSm] = useState(false);
  const [currentSection, setCurrentSection] = useState(fields?.[0] ? fields?.[0] : null) as any;
  const [generatingError, setGeneratingError] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const [showGenerate, setShowGenerate] = useState(false);
  const [sectionIsGenerating, setSectionIsGenerating] = useState(false);
  const [errors, setErrors] = useState([] as ValidationError[]);

  const currentSectionIndex = useMemo(() => {
    const index = fields.findIndex((item) => item.id === currentSection?.id);
    document.getElementById(`section-${index}-textarea`)?.focus();
    return index;
  }, [currentSection, fields]);

  const variables = useMemo(
    () =>
      Array.from(
        new Set(
          fields
            .map(
              ({ content }) =>
                content?.match?.(/{{(.*?)}}/g)?.map((variable) => variable.replace(/{{|}}/g, '').trim()) ?? [],
            )
            .flat(),
        ),
      ),
    [fields],
  );

  useEffect(() => {
    if (decoratedChapter) {
      methods.reset({ chapter: decoratedChapter });
    }
  }, [decoratedChapter]);

  return (
    <TrackContext.Provider
      value={{
        editEntry,
        currentSection,
        setCurrentSection,
        generatingError,
        setGeneratingError,
        setShowGenerate,
        setSectionIsGenerating,
        sectionIsGenerating,
        currentSectionIndex,
        setErrors,
        errors,
      }}
    >
      <FormProvider {...methods}>
        <main className="drawer-content px-6 pb-6 min-h-[calc(100vh_-_theme(spacing.16))]">
          <Dialog open={deleteSection !== null} onOpenChange={() => setDeleteSection(null)}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>{t('chapter.removeSection.headline')}</DialogTitle>
              </DialogHeader>
              <div className="flex gap-3 justify-end">
                <DialogClose asChild>
                  <Button variant={'secondary'}>{t('chapter.removeSection.cancel')}</Button>
                </DialogClose>
                <DialogClose
                  asChild
                  onClick={async () => {
                    removeSection(deleteSection as any);
                    await editEntry({
                      state: 'draft',
                      content: watch('chapter.content'),
                    });
                    setDeleteSection(null);
                  }}
                >
                  <Button variant={'destructive'}>{t('chapter.removeSection.delete')}</Button>
                </DialogClose>
              </div>
            </DialogContent>
          </Dialog>
          <ChapterSplitPasteTextModal id="ChapterSplitPasteTextModal" />
          <ChapterGenerateModal
            setShowGenerate={setShowGenerate}
            showGenerate={showGenerate}
            chapter={chapter}
            mutate={mutateChapter}
          />
          <VariableEditorModal onVariables={(v) => setShowGenerate(v)} project={project} chapter={chapter} />
          {chapter.state === 'generating' && <ChapterGeneratingModal mutate={mutateChapter} />}
          {chapter.state === 'errored' && <ChapterErrorModal chapter={chapter} mutate={mutateChapter} />}
          <GeneratingFailedModal id="generatingFailedModal" chapter={chapter} />
          <ChapterCopyModal id="copyModal" />
          <ChangeLanguageModal id="changeLanguageModal" chapter={chapter} />
          <ChapterTranslateModal id="chapterTranslateModal" />
          {shortID && <SpeechToTextModal id="speechToTextModal" shortID={shortID} mutate={mutate} />}
          <SectionTranslateModal id="sectionTranslateModal" sectionIndex={currentSectionIndex} />
          <ChapterEditModal id="editModal" chapter={chapter} mutate={mutate} />

          <div className="grid sticky top-0 grid-cols-12 grid-rows-[min-content] gap-y-12 py-6 lg:gap-x-12 bg-background z-10">
            <TopNav>
              <div className="flex grow items-center justify-end space-x-4 pt-1.5">
                <div className="flex items-center justify-end gap-4">
                  <Controller
                    name={'chapter.content.voiceID'}
                    control={methods.control}
                    rules={{
                      required: t('global.required'),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <VoiceSelect
                        showModal={sm}
                        onChange={async (e) => {
                          onChange(e.id);
                          const usedVoices = getUsedLocalVoices(methods.watch('chapter.content'));
                          await editEntry({
                            state: 'draft',
                            content: {
                              ...methods.getValues('chapter.content'),
                              voiceID: e.id,
                            },
                            voice_preset: usedVoices,
                          });
                        }}
                        value={value}
                      />
                    )}
                  />

                  {chapter?.content?.sections?.length > 0 &&
                    (!(chapter.currentAudio?.length && methods.watch('chapter.state') === 'generated') ||
                      variables.length > 0) && (
                      <Button
                        className="w-full sm:w-auto flex justify-center self-center my-1"
                        disabled={
                          methods.watch('chapter.state') === 'generating' ||
                          !methods.watch('chapter.content.sections')?.length
                        }
                        onClick={() => {
                          const errors = methods.getValues('chapter.content.sections').reduce((acc, section, index) => {
                            if (!section.content?.trim()?.length) {
                              acc.push({ type: section.type, index });
                            }
                            return acc;
                          }, [] as ValidationError[]);
                          console.log('errors', errors);

                          const globalVoiceError = [undefined, null, ''].includes(
                            methods.getValues('chapter.content.voiceID'),
                          );

                          if (errors.length === 0 && !globalVoiceError) {
                            publish('variableEditor', true);
                          } else {
                            if (globalVoiceError) {
                              toast.error('Es ist keine globale Stimme hinterlegt. Bitte wähle eine Stimme aus.');
                            }
                            if (errors.length > 0) {
                              setErrors(errors);
                              console.log('errors', errors);

                              if (errors.length === 1 && errors[0].type === 'speech') {
                                toast.error('Die Sektion ist leer. Bitte speichere deine Eingabe.');
                              } else {
                                toast.error('Es gibt leere Sektionen. Bitte gib einen Inhalt ein.');
                              }
                              const [firstError] = errors;
                              const element = document.querySelectorAll('.section')[firstError.index];
                              element.scrollIntoView({ behavior: 'smooth' });
                            }
                          }
                        }}
                      >
                        <div className="flex items-center lg:gap-x-2 lg:p-2">
                          {methods.watch('chapter.state') === 'generating' ? (
                            <Loader size={5} />
                          ) : (
                            <SparklesIcon className="w-5 h-5 text-base" />
                          )}
                          Generieren
                        </div>
                      </Button>
                    )}
                </div>
              </div>
            </TopNav>
          </div>
          <div className="flex flex-col items-center grow px-4 sm:px-0">
            {fields.map((section: any, index: number) => (
              <Section htmlId={section.id} key={section.id} index={index} remove={remove} fields={fields} />
            ))}
            <AddSection append={append} />
          </div>
        </main>
        <Sheet open={showHistory} onOpenChange={setShowHistory}>
          <SheetContent>
            <SideNavHistory />
          </SheetContent>
        </Sheet>
        {chapter.state === 'generated' && (
          <Waveform chapter={chapter} project={project} currentAudio={chapter.currentAudio} variables={variables} />
        )}
      </FormProvider>
    </TrackContext.Provider>
  );
};

const SkeletonLayout = () => {
  return (
    <main className="drawer-content px-6 pb-6 min-h-[calc(100vh_-_theme(spacing.16))]">
      <div className="grid sticky top-0 grid-cols-12 grid-rows-[min-content] gap-y-12 py-6 lg:gap-x-12 bg-background z-10 items-center">
        <TopNav>
          <div className="flex grow items-center justify-between space-x-4 pt-1.5">
            <div className="flex grow items-baseline space-x-4 pt-1.5" />
            <div className="sticky bottom-0 bg-base flex items-center justify-end p-2 pt-1.5 pb-3 gap-x-4">
              <Skeleton className="w-32 h-10 border rounded-md flex items-center justify-center">
                <Skeleton className="w-20 h-4 bg-border rounded-md" />
              </Skeleton>
              <Skeleton className="w-32 h-10 bg-border rounded-md" />
            </div>
          </div>
        </TopNav>
      </div>
      <div className="flex flex-col items-center grow px-4 sm:px-0">
        <Section.Skeleton />
        <Section.Skeleton />
      </div>
    </main>
  );
};
