import { EntryResource, getEntryAsset } from 'ec.fdk';

export function negotiateAsset(asset: any, size: number = 800, thumb: boolean = false) {
  if (!asset || typeof asset !== 'object') {
    return null;
  }

  // assets neue
  if ('file' in asset) {
    if (thumb) {
      if (size) {
        const f = asset.thumbnails.find((t) => t.dimension === size);
        if (f) {
          return f.url;
        }
      }
      return asset.thumbnails[0].url;
    }
    if (size) {
      if (asset.mimetype === 'image/svg+xml') {
        return asset.file.url;
      }

      if (!size || Math.max(asset.file.resolution.width, asset.file.resolution.height) <= size) {
        return asset.file.url;
      }

      const f = asset.fileVariants.find((v) => Math.max(v.resolution.width, v.resolution.height) === size);
      if (f) {
        return f.url;
      }
    }
    return asset.file.url;
  }
}

export default function useAsset(entry: EntryResource, field: string, size = 800, thumbnail: boolean = false) {
  try {
    const asset = getEntryAsset(field, entry);
    return negotiateAsset(asset, size, thumbnail);
  } catch (err) {
    console.error('could_not_resolve_asset', err);
    return null;
  }
}
