import { Button } from '@/components/ui/button';
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import useAsset from '@/hooks/useAsset';
import { getFlagEmoji } from '@/utils';
import { PhotoIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const StationCard = ({ station, tour }) => {
  const { t } = useTranslation();
  const cover = useAsset(station, 'cover', 800);
  const languages = useMemo(() => tour?.languages?.split(',') ?? [], [tour]);

  return (
    <Card>
      <Link to={station.id}>
        <CardHeader>
          {cover ? (
            <img src={cover} className="mb-3 rounded-lg aspect-video object-cover" alt={station.title[languages[0]]} />
          ) : (
            <div className="w-full aspect-video bg-card-foreground flex items-center rounded-lg mb-3 justify-center">
              <PhotoIcon className="w-8 h-8 text-card" />
            </div>
          )}
          <CardTitle className="flex gap-3 ">
            {station.title[languages[0]]}
            {languages.map((lang) => (
              <span key={lang} className="text-lg">
                {getFlagEmoji(lang)}
              </span>
            ))}
          </CardTitle>
        </CardHeader>
      </Link>

      <CardFooter className="flex self-end justify-between mt-auto gap-3">
        <CardDescription className="text-xs">{dayjs(station?.created).format('DD.MM.YYYY')}</CardDescription>
        <div>
          <Button className="mr-3" asChild>
            <Link to={station.id}>{t('translation:audioGuide.list.edit')}</Link>
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

StationCard.Skeleton = () => (
  <Card>
    <CardHeader>
      <Skeleton className="aspect-video w-full rounded-lg bg-border" />
      <CardTitle className="flex gap-6 ">
        <Skeleton className="h-6 w-8/12 rounded-lg bg-border mt-3" />
      </CardTitle>
    </CardHeader>

    <CardFooter className="flex self-end justify-between mt-auto">
      <CardDescription>
        <Skeleton className="h-3 w-20 rounded-sm bg-border" />
      </CardDescription>
      <Skeleton className="h-10 w-28 rounded-lg bg-border" />
    </CardFooter>
  </Card>
);

export default StationCard;
