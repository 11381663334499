import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { usePlainFdk } from '../../fdk';
import { Modal, hideModal } from '../Modal';
import { Button } from '../ui/button';
import { Label } from '../ui/label';

export function ChapterCopyModal({ id }: { id: string }) {
  const fdk = usePlainFdk();
  const [pending, setPending] = useState(false);
  const form = useFormContext();
  const navigate = useNavigate();
  const { projectID, shortID } = useParams();
  const { t } = useTranslation('translation');

  const chapter = form?.watch('chapter');

  const clone: any = useCallback(async () => {
    setPending(true);
    try {
      const res = await fdk.model('chapter').createEntry({
        ...chapter,
        historyAudio: [],
        currentAudio: [],
        audio_resources: [],
        content: {
          ...chapter.content,
          sections: chapter.content.sections.map((section: any) => ({
            ...section,
            modified: undefined,
            generated: undefined,
            timestamp: undefined,
            jobID: undefined,
            generating: undefined,
            assets: undefined,
            response: undefined,
            _prev: undefined,
            assetID: undefined,
          })),
        },
        name: `${chapter.name} (Kopie)`,
      });
      return navigate(`/${shortID}/projects/${projectID}/chapters/${res.id}`);
    } catch (error) {
      console.error('error', error);
      toast.error(t('chapter.chapterCopyModal.error'));
    } finally {
      hideModal('copyModal');
      setPending(false);
    }
  }, [chapter]);

  return (
    <Modal id={id as string} headline={t('chapter.chapterCopyModal.headline')}>
      <Label>{t('chapter.chapterCopyModal.description')}</Label>
      <div className="flex justify-end py-4 gap-x-2">
        <Button type="button" variant="secondary" onClick={() => hideModal('copyModal')}>
          {t('chapter.chapterCopyModal.cancel')}
        </Button>
        <Button type="button" disabled={pending} onClick={clone}>
          {t('chapter.chapterCopyModal.save')}
          {pending && <span className="loading loading-infinity loading-xs" />}
        </Button>
      </div>
    </Modal>
  );
}
