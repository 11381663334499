import { Button } from '@/components/ui/button';
import { DataTableColumnHeader } from '@/components/ui/data-table/data-table-column-header';
import DataTable from '@/components/ui/data-table/data-table-skeleton';
import { PlusIcon } from '@heroicons/react/20/solid';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../Layout';
import CreateStudioForm from '../components/CreateStudioForm';
import { Modal, hideModal } from '../components/Modal';
import { TopNav } from '../components/TopNav';
import { useStudios } from '../hooks/useStudios';

export default function Studios() {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');
  const [sort, setSort] = useState<any | null>(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const options = useMemo(() => {
    const options = {
      page: pagination.pageIndex + 1,
      size: pagination.pageSize,
    } as any;
    searchValue.length > 2 && (options['name~'] = searchValue);
    return options;
  }, [searchValue, pagination]);

  const { data: studios, isLoading } = useStudios(
    pagination.pageIndex + 1,
    pagination.pageSize,
    options?.['name~'],
    sort,
  );

  const columns: ColumnDef<any>[] = useMemo(() => {
    return [
      {
        accessorKey: 'title',
        header: ({ column }) => <DataTableColumnHeader column={column} title={t('translation:dataTable.name')} />,
        cell: ({ row }) => <div className="w-[240px]">{row.getValue('title')}</div>,
      },
      {
        accessorKey: 'created',
        header: ({ column }) => <DataTableColumnHeader column={column} title={t('translation:dataTable.created')} />,
        cell: ({ row }) => <div className="w-[240px]">{dayjs(row.getValue('created')).format('DD.MM.YYYY')}</div>,
      },
    ];
  }, []);

  useEffect(() => {
    if (searchValue === '') {
      if (studios?.total === 0 && searchValue === '') {
        navigate('/onboarding', { replace: true });
      }
      if (studios?.total === 1 && searchValue === '') {
        navigate(`/${studios['_embedded']['ec:datamanager'].shortID}/projects`, { replace: true });
      }
    }
  }, [studios, searchValue]);

  return (
    <Layout>
      <TopNav>
        <div className="flex grow items-center space-x-4">
          <div className="mr-auto">
            <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
              {t('studios.headline')}
            </h1>
          </div>
          <Button variant="outline" onClick={() => navigate('/onboarding/')}>
            <PlusIcon className="w-5 h-5" /> {t('studios.addStudio')}
          </Button>
        </div>
      </TopNav>
      <StudioCreateModal id="createModal" />
      <div className="col-span-12 xl:col-span-10">
        <section className="card  overflow-hidden bg-base-100 shadow-sm">
          <DataTable
            pending={isLoading}
            onSort={setSort}
            skeletonRows={5}
            skeletonColumns={2}
            searchValue={searchValue}
            setSearchValue={(x) => {
              setPagination({
                pageIndex: 0,
                pageSize: pagination.pageSize,
              });
              setSearchValue(x);
            }}
            pagination={pagination}
            setPagination={setPagination}
            pageCount={Math.ceil(studios?.total / pagination?.pageSize)}
            onRowClick={(row) => {
              switch (row.original.config?.publicConfig?.type) {
                case 'guide':
                  return navigate(`/${row.original.shortID}/guide`);
                default:
                  return navigate(`/${row.original.shortID}/projects`);
              }
            }}
            data={studios?.items ?? []}
            columns={columns}
          />
        </section>
      </div>
    </Layout>
  );
}

export function StudioCreateModal({ id }: { id: string }) {
  const { t } = useTranslation('translation');
  return (
    <Modal id={id}>
      <h3 className="font-bold text-lg">{t('studios.createStudio')}</h3>
      <CreateStudioForm
        checkoutSessionID={'checkoutSessionID' as string}
        onAbort={() => {
          hideModal('createModal');
        }}
      />
    </Modal>
  );
}
