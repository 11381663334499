import { useFdk, usePlainFdk } from '@/fdk';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { mutate } from 'swr';

export function GuideCreateAndRedirect() {
  const fdk = usePlainFdk();
  const { data: tourList, isValidating } = useFdk({
    action: 'entryList',
    model: 'guide_tours',
    options: {
      size: 1,
    },
  });

  useEffect(() => {
    if (tourList?.total === 0) {
      fdk
        .model('guide_tours')
        .createEntry({
          title: {
            de_DE: null,
          },
          description: {},
          cover: null,
          duration: null,
          publish_date: null,
          tags: [],
          stations: [],
          languages: [
            {
              label: 'Deutsch',
              value: 'de_DE',
              flag: '🇩🇪',
            },
          ],
        })
        .then(mutate);
    }
  }, [tourList]);

  if (isValidating) {
    return null;
  }

  return <Navigate replace to={tourList?.items?.[0]?.id} />;
}
