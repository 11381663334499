import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { Slider } from '@/components/ui/slider';
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import { useStudioApi } from '@/hooks/useStudioAPI';
import { formatCurrency } from '@/lib/utils';
import { classNames } from '@/utils';
import { useOidc } from '@axa-fr/react-oidc';
import { Cog6ToothIcon, ExclamationTriangleIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import relativeTime from 'dayjs/plugin/relativeTime';
import { fetcher } from 'ec.fdk';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Layout } from '../Layout';
import { PageLoader } from '../components/PageLoader';
import StripeBilling from '../components/StripeBillingLink';
import { TopNav } from '../components/TopNav';
dayjs.extend(relativeTime);
dayjs.locale('de');

export default function Settings() {
  const { t } = useTranslation('translation');
  const oidc = useOidc();
  const { data: settings, mutate, isLoading: isLoadingSettings } = useStudioApi('settings');
  const { shortID } = useParams();

  const {
    data: abo,
    mutate: mutateAbo,
    isLoading,
    error,
  } = useSWR(`${import.meta.env.VITE_API_URL}/${shortID}/abo`, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  if (isLoading || isLoadingSettings) {
    return <PageLoader />;
  }

  return (
    <Layout>
      <TopNav>
        <div className="flex grow items-center space-x-4">
          <div className="mr-auto">
            <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
              {t('settings.headline')}
            </h1>
          </div>
          <div className="flex gap-2">
            <Button variant="secondary">
              <a className="cursor-pointer flex p-2 gap-2 items-center" href={`/${shortID}/settings/abo-manage`}>
                <Squares2X2Icon className="w-5 h-5" />
                {t('settings.manageAbo')}
              </a>
            </Button>
            <StripeBilling />
            <Button variant="secondary">
              <a
                className="cursor-pointer flex p-2 gap-2 items-center"
                onClick={async () => oidc.login('/', { prompt: 'profile' })}
              >
                <Cog6ToothIcon className="w-5 h-5" />
                {t('settings.mailAndSecurity')}
              </a>
            </Button>
          </div>
        </div>
      </TopNav>
      {!error ? (
        <Card className="border col-span-12 mt-6 overflow-hidden  xl:col-span-10">
          <div className="overflow-x-auto  ">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="text-sm">{t('settings.bookedAbo')}</TableCell>
                  <TableCell className="text-sm">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={async () => {
                        try {
                          const res = await fetch(`${import.meta.env.VITE_API_URL}/${shortID}/billing-portal`, {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              return_url: window.location.href,
                            }),
                          });
                          if (!res.ok) {
                            toast.error(t('settings.redirectError'));
                            console.error('Error creating billing portal session', res);
                            return;
                          }
                          const { url } = await res.json();
                          window.location.href = url;
                        } catch (error) {
                          console.error('error', error);
                          toast.error(t('settings.redirectError'));
                        }
                      }}
                    >
                      {abo?.product}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-sm">Status</TableCell>
                  <TableCell className="text-sm">
                    {abo?.subscription.status === 'trialing' && (
                      <div className="badge badge-primary badge-outline">{t('settings.testMode')}</div>
                    )}
                    {abo?.subscription.status === 'active' && (
                      <div className="badge badge-primary badge-outline">{t('settings.active')}</div>
                    )}
                    {abo?.subscription.status === 'canceled' && (
                      <div className="badge badge-error badge-outline">{t('settings.canceled')}</div>
                    )}
                    {(abo?.subscription.status === 'incomplete' ||
                      abo?.subscription.status === 'incomplete_expired' ||
                      abo?.subscription.status === 'past_due' ||
                      abo?.subscription.status === 'unpaid') && (
                      <div className="badge badge-error badge-outline">{t('settings.paymentPending')}</div>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-sm">{t('settings.usedVolume')}</TableCell>
                  <TableCell className="text-sm">
                    {abo?.usage.current}/{abo?.usage.total} {t('settings.minutes')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-sm">{t('settings.newVolume')}</TableCell>
                  <TableCell className="text-sm">{dayjs.unix(abo?.subscription.period_end).fromNow()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-sm">{t('settings.nextPayment')}</TableCell>
                  <TableCell className="text-sm">
                    {dayjs.unix(abo?.next_invoice.next_payment_attempt).fromNow()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-sm">{t('settings.invoiceAmount')}</TableCell>
                  <TableCell className="text-sm">{abo?.next_invoice.amount_due / 100} €</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-sm">{t('settings.disableLogging')}</TableCell>
                  <TableCell className="text-sm">
                    <label className="flex gap-3 items-center cursor-pointer">
                      <Checkbox
                        checked={settings?.disableLogging}
                        onCheckedChange={async (disableLogging: boolean) => {
                          const res = await fetch(`${import.meta.env.VITE_API_URL}/${shortID}/settings`, {
                            method: 'PUT',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              ...(settings ?? {}),
                              disableLogging,
                            }),
                          });

                          const data = await res.json();
                          await mutate(data, { revalidate: false });
                        }}
                      />
                      <div className="text-[11px] text-foreground/60">{t('settings.disableLoggingHint')}</div>
                    </label>
                  </TableCell>
                </TableRow>
                <TableRow className={classNames(abo?.usage?.limitReached && 'bg-red-600/10 hover:bg-red-600/15')}>
                  <TableCell className="flex gap-2 items-center">
                    {abo?.usage?.limitReached && <ExclamationTriangleIcon className="w-6 h-6 text-red-600/80" />}
                    {t('settings.limit')}
                  </TableCell>
                  <TableCell className="text-sm">
                    <div className="flex gap-3 items-center">
                      <Slider
                        className="w-3/4 cursor-ew-resize"
                        defaultValue={[settings?.limit ?? 500]}
                        min={1}
                        max={5000}
                        step={1}
                        onValueChange={async ([limit]: Array<number>) => mutate({ ...settings, limit }, false)}
                        onValueCommit={async ([limit]: Array<number>) => {
                          const res = await fetch(`${import.meta.env.VITE_API_URL}/${shortID}/settings`, {
                            method: 'PUT',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              ...(settings ?? {}),
                              limit,
                            }),
                          });

                          const data = await res.json();
                          await mutate(data, { revalidate: false });
                          await mutateAbo();
                        }}
                      />
                      <div className="text-[11px] text-foreground/60 whitespace-nowrap">
                        {settings?.limit ?? 500} {t(settings?.limit === 1 ? 'settings.minute' : 'settings.minutes')}{' '}
                        {abo?.usage?.perMinute && (
                          <>({formatCurrency((settings?.limit ?? 500) * abo?.usage?.perMinute)})</>
                        )}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Card>
      ) : (
        <Card className="border col-span-12 mt-6 overflow-hidden  xl:col-span-10">
          <div className="flex justify-center items-center p-8">{t('settings.noSubscriptionFound')}</div>
        </Card>
      )}
    </Layout>
  );
}
