import { useOidc } from '@axa-fr/react-oidc';
import { fetcher } from 'ec.fdk';
import { CircleUser, Menu } from 'lucide-react';
import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import Logo from './components/Logo';
import { Button } from './components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './components/ui/dropdown-menu';
import { Sheet, SheetContent, SheetTrigger } from './components/ui/sheet';
import { useTheme } from './Theme';
import { classNames } from './utils';

export function Layout({ children, aside = null }: PropsWithChildren & { aside?: ReactNode }) {
  return (
    <main className="relative">
      <Header />
      <div className="flex grid-template-rows">
        {aside && <div className="border-r bg-muted relative">{aside}</div>}
        <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] max-w-7xl mx-auto flex-1 flex-col gap-4  p-4 md:gap-8 md:p-10">
          <div className="h-full">{children}</div>
        </main>
      </div>
    </main>
  );
}

export function LayoutEditor({ children, aside = null }: PropsWithChildren & { aside?: ReactNode }) {
  return (
    <main className="bg-base-100 relative">
      <Header />
      <div className="flex grid-template-rows">
        {aside && (
          <div className="border-r bg-muted relative overflow-y-auto h-[calc(100vh_-_theme(spacing.16))]">{aside}</div>
        )}

        <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 md:gap-8">
          <div className="h-[calc(100vh_-_theme(spacing.16))] overflow-y-auto">{children}</div>
        </main>
      </div>
    </main>
  );
}

const Header = () => {
  const { shortID, guideID } = useParams<{ shortID?: string; guideID?: string }>();
  const { t } = useTranslation('translation');
  const oidc = useOidc();
  const location = useLocation();
  const theme = useTheme();

  const { data: abo, isLoading } = useSWR(shortID && `${import.meta.env.VITE_API_URL}/${shortID}/abo`, fetcher, {
    revalidateOnFocus: true,
    refreshInterval: 60,
  });

  const percentage = useMemo(
    () => (abo?.usage?.current / abo?.usage?.total) * 100,
    [abo?.usage?.current, abo?.usage?.total],
  );

  const NAVIGATION = useMemo(() => {
    if (!shortID) {
      return [];
    }

    return {
      studio: [
        {
          to: `/${shortID}/projects`,
          label: t('header.projects'),
        },
        {
          to: `/${shortID}/voices`,
          label: t('header.voices'),
        },
      ],
      guide: [
        {
          to: `/${shortID}/guide/${guideID}`,
          label: t('header.stations'),
        },
        {
          to: `/${shortID}/guide/${guideID}/settings`,
          label: t('header.settings'),
        },
        {
          to: `/${shortID}/guide/${guideID}/app`,
          label: t('header.app'),
        },
      ],
    }[theme ?? 'studio'];
  }, [shortID, guideID, theme]);

  return (
    <header className="sticky bg-muted top-0 flex h-16 items-center gap-4 border-b z-20 px-4 md:px-6">
      <nav className="hidden w-full flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
        <Link to="/" className="flex items-center gap-2 text-lg mr-3 font-semibold md:text-base">
          <div className="mx-4 flex items-center shrink-0 gap-2 font-black">
            <Logo />
          </div>
        </Link>
        {NAVIGATION?.map((item: any) => (
          <NavLink
            end
            to={item.to}
            key={item.label}
            className={({ isActive }) =>
              classNames(
                'text-muted-foreground transition-colors hover:text-foreground',
                isActive ? 'text-primary' : '',
              )
            }
          >
            {item.label}
          </NavLink>
        ))}
      </nav>
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="shrink-0 md:hidden">
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <nav className="grid gap-6 text-lg font-medium">
            <Link to="#" className="flex items-center gap-2 text-lg font-semibold">
              <span className="sr-only">Lizzen</span>
            </Link>
            {NAVIGATION?.map((item: any) => (
              <NavLink
                end
                key={item.label}
                to={item.to}
                className={({ isActive }) =>
                  classNames('text-muted-foreground hover:text-foreground flex-', isActive ? 'text-primary' : '')
                }
              >
                {item.label}
              </NavLink>
            ))}
          </nav>
        </SheetContent>
      </Sheet>
      <div className="flex items-center gap-4 md:gap-2 lg:gap-4">
        <Link to={`/${shortID}/settings`} className="cursor-pointer">
          <div
            className={classNames(
              'inline-flex items-center rounded-full border text-xs py-0 px-1.5 font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
              isLoading && 'animate-pulse bg-muted hover:bg-muted/50 text-muted-foreground/50',
              percentage < 80 && 'bg-green-400 hover:bg-green-400/80 text-green-800',
              percentage >= 80 && percentage < 100 && 'bg-yellow-400 hover:bg-yellow-400/80 text-yellow-800',
              percentage >= 100 && 'bg-red-400 hover:bg-red-400/80 text-red-800',
            )}
          >
            {abo?.usage ? `${abo?.usage?.current}/${abo?.usage?.total}` : '?/?'}
          </div>
        </Link>
        <div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon" className="rounded-full">
                <CircleUser className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {location.pathname !== '/' && (
                <>
                  <Link to={`/${shortID}/settings`} className="cursor-pointer">
                    <DropdownMenuItem>{t('translation:global.settings')} </DropdownMenuItem>
                  </Link>
                  <DropdownMenuSeparator />
                </>
              )}
              <DropdownMenuItem className="cursor-pointer" onClick={async () => await oidc.logout('/')}>
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
};
