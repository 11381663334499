import { fetchEventSource } from '@microsoft/fetch-event-source';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useCallback, useContext, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../hooks/useStudios';
import { TrackContext } from '../../routes/ChapterDetail';
import Loader from '../Loader';
import { showModal } from '../Modal';
import { Button } from '../ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Label } from '../ui/label';
import { Progress } from '../ui/progress';

dayjs.extend(duration);

export default function ChapterGenerateModal({ showGenerate, setShowGenerate, mutate, chapter }) {
  const { id: chapterID } = chapter;
  const [pending, setPending] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);
  const { setGeneratingError } = useContext(TrackContext);
  const { shortID } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const { data: quota, error } = useSWR(
    `${import.meta.env.VITE_API_URL}/${shortID}/check-quota/${chapterID}${
      showGenerate?.index === null && showGenerate?.variables?.length
        ? `?variants=${showGenerate?.variables?.length}`
        : ''
    }`,
    (url) => (isKaufland ? { status: 200 } : fetcher(url)),
  );

  const isKaufland = useMemo(() => ['9de61e3e', '25469537'].includes(shortID as string), [shortID]);

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    const body: any = {
      chapterID: chapter.id,
      variables: showGenerate?.variables,
      fileName: showGenerate?.fileName,
      index: showGenerate?.index ?? null,
    };

    fetchEventSource(`${import.meta.env.VITE_QUEUE_URL}/${shortID}/addJob`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      async onopen() {
        setProgress(0);
        setPending(true);
      },
      async onmessage({ data, event }) {
        if (event === 'progress') {
          const { percentage } = JSON.parse(data);
          setProgress(percentage);
        }
        if (event === 'completed') {
          toast.success('Erfolgreich generiert');
          setShowGenerate(false);
          await mutate();
        }
        if (event === 'failed') {
          toast.error('Fehler beim Generieren');
        }
      },
      async onclose() {
        toast.dismiss(shortID);
        setPending(false);
        setSubmitting(false);
      },
      onerror() {
        toast.error('Fehler beim Generieren');
        throw new Error('no_retry');
      },
      openWhenHidden: true,
    }).catch(() => null);
  }, [chapterID, showGenerate]);

  if (error) {
    toast.error(t('chapter.chapterGeneratModal.quotaErrorMessage'));
    setGeneratingError(error);
    showModal('generatingFailedModal');
    setShowGenerate(false);
  }

  if (quota && quota.status && quota.status !== 200) {
    return (
      <Dialog onOpenChange={() => setShowGenerate(false)} open={showGenerate}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {quota.status === 403 ? (
                <h3 className="font-bold text-lg">{t('chapter.chapterGeneratModal.quotaExhaustedMessage')}</h3>
              ) : (
                <h3 className="font-bold text-lg">{t('chapter.chapterGeneratModal.subscriptionAttentionMessage')}</h3>
              )}
            </DialogTitle>
          </DialogHeader>
          <div>
            <Label>
              {quota.status === 403 && t('chapter.chapterGeneratModal.upgradeMessage')}
              {quota.status === 402 && t('chapter.chapterGeneratModal.paymentProblemMessage')}
            </Label>
          </div>
          <div className="grid grid-cols-2 items-end py-4 gap-x-4">
            <Button
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                setShowGenerate(false);
              }}
            >
              {t('chapter.chapterGeneratModal.cancelButtonText')}
            </Button>
            <div className="col-span-1">
              <Button
                className="btn btn-primary grow"
                onClick={() => {
                  navigate(`/${shortID}/settings/abo-manage`);
                }}
              >
                {t('chapter.chapterGeneratModal.manageSubscriptionButtonText')}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog onOpenChange={() => setShowGenerate(false)} open={!!showGenerate}>
      {pending ? (
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {t(
                ![null, undefined].includes(showGenerate?.index)
                  ? `chapter.chapterGeneratingModal.headlineVariant`
                  : 'chapter.chapterGeneratingModal.headline',
              )}
            </DialogTitle>
          </DialogHeader>

          <div className="flex flex-col">
            <div>
              <Label>
                {t(
                  ![null, undefined].includes(showGenerate?.index)
                    ? 'chapter.chapterGeneratingModal.descriptionVariant'
                    : 'chapter.chapterGeneratingModal.description',
                )}
              </Label>
            </div>
            {pending && (
              <div className="w-full py-2 px-4 mt-4">
                <Progress className="progress w-full" value={progress} />
                <span className="text-center block text-xs mt-1">
                  {progress <= 90
                    ? t('chapter.chapterGeneratingModal.pendingMessage')
                    : t('chapter.chapterGeneratingModal.pendingSoonFinishedMessage')}
                </span>
              </div>
            )}
          </div>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {t(
                ![null, undefined].includes(showGenerate?.index)
                  ? `chapter.chapterGeneratModal.titleVariant`
                  : 'chapter.chapterGeneratModal.title',
              )}
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-col">
            <div>
              {isKaufland ? (
                <Label>
                  {t(
                    ![null, undefined].includes(showGenerate?.index)
                      ? 'chapter.chapterGeneratModal.descriptionKaudlandVariant'
                      : 'chapter.chapterGeneratModal.descriptionKaufland',
                  )}
                </Label>
              ) : (
                <Label>
                  {t(
                    ![null, undefined].includes(showGenerate?.index)
                      ? 'chapter.chapterGeneratModal.descriptionOneVariant'
                      : 'chapter.chapterGeneratModal.descriptionOne',
                  )}
                  {quota ? (
                    dayjs.duration(quota.expected_quota, 'seconds').humanize()
                  ) : (
                    <Loader size={3} textColor="text-foreground" fillColor="fill-background" />
                  )}
                  {t('chapter.chapterGeneratModal.descriptionTwo')}
                </Label>
              )}
            </div>

            <div className="flex justify-end py-4 gap-x-2">
              <Button
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  setShowGenerate(false);
                }}
              >
                {t('chapter.chapterGeneratModal.cancel')}
              </Button>
              <Button onClick={onSubmit} type="submit" disabled={submitting || pending || !quota || !chapterID}>
                {!quota && (
                  <div className="mx-2 flex items-center">
                    <Loader size={3} textColor="text-foreground" fillColor="fill-background" />
                  </div>
                )}
                {t('chapter.chapterGeneratModal.generateButtonText')}
                {(submitting || pending || !quota || !chapterID) && (
                  <span className="loading loading-infinity loading-xs" />
                )}
              </Button>
            </div>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
}
