import { useTheme } from '@/Theme';
import { useEffect, useMemo, useState } from 'react';
import LizzenLoaderGuide from '../assets/lizzen-loader-guide.gif';
import LizzenLoader from '../assets/lizzen-loader.gif';

export default function AILoader({
  type,
  chapter,
}: {
  type: 'podcast' | 'document' | 'studio' | 'empty';
  chapter?: boolean;
}) {
  const theme = useTheme();
  const [i, setI] = useState(0);

  const text = useMemo(
    () =>
      ({
        podcast: {
          headline: 'Der Content-Plan deines Podcasts wird jetzt erstellt',
          steps: [
            'Starte Brainstorming',
            'Erstelle einen Content-Plan',
            'Fasse den Inhalt zusammen',
            'Kontrolliere meinen Text',
            'Schließe den Content-Plan ab',
          ],
        },
        document: {
          headline: 'Aus deinem Dokument wird nun ein Kapitel erstellt',
          steps: [
            'Öffne die Datei',
            'Beginne zu lesen',
            'Sortiere die Buchstaben',
            'Speichere das Gelesene ab',
            'Erstelle das Kapitel',
          ],
        },
        empty: {
          headline: 'Dein Kapitel wird angelegt',
          steps: ['Öffne den Speicher', 'Erstelle das Kapitel', 'Speichere das Kapitel'],
        },
        studio: {
          headline: 'Dein Studio wird jetzt erstellt',
          steps: [
            'Erstelle dein Studio',
            'Richte die Datenbank ein',
            'Konfiguriere das Studio',
            'Bereite die Kapitel vor',
          ],
        },
      }[type] ?? {
        headline: chapter ? 'Dein Kapitel wird jetzt erstellt' : 'Dein Projekt wird jetzt erstellt',
        steps: [
          !chapter && 'Erstelle das Projekt',
          chapter ? 'Erzeuge Sektionen' : 'Füge Kapitel hinzu',
          !chapter && 'Speichere das Projekt',
          'Erstelle die Kapitel',
          'Speichere die Kapitel',
        ].filter(Boolean),
      }),
    [type],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setI((old) => (old + 1) % text.steps.length);
    }, 3 * 1000);

    return () => clearInterval(interval);
  }, [text.steps.length]);

  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="flex flex-col gap-8 items-center text-center">
        <img src={theme === 'guide' ? LizzenLoaderGuide : LizzenLoader} className="w-32 h-auto" />
        <h1 className="text-2xl">{text.headline}…</h1>
        <div className="text-xl">{text.steps[i]}…</div>
      </div>
    </div>
  );
}
