import { useTheme } from '@/Theme';
import LizzenLoaderGuide from '../assets/lizzen-loader-guide.gif';
import LizzenLoader from '../assets/lizzen-loader.gif';

export function PageLoader() {
  const theme = useTheme();

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-background bg-opacity-90">
      <img src={theme === 'guide' ? LizzenLoaderGuide : LizzenLoader} className="w-20 h-auto" />
    </div>
  );
}
