import { PageLoaderWithNav } from '@/components/PageLoaderWithNav';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useFdk } from '@/fdk';
import { Layout } from '@/Layout';
import { getFlagEmoji, languages as locales } from '@/utils';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { QRCode } from './QRCode';

export function GuideApp() {
  const { guideID } = useParams();
  const { i18n } = useTranslation('translation');
  const [language, setLanguage] = useState<string | undefined>(undefined);
  const { data: tour, isLoading } = useFdk({
    action: 'getEntry',
    model: 'guide_tours',
    entryID: guideID,
    options: {
      _fields: ['languages'],
    },
  });

  const languages = useMemo(() => tour?.languages?.split(',') ?? [], [tour]);

  const getLanguage = useCallback(
    (lang: string) => {
      const found = locales[i18n.language].find((l) => l.language === lang);

      return {
        language: lang,
        ...(found ?? {}),
        flag: getFlagEmoji(found?.countryCode ?? lang),
      };
    },
    [i18n.language],
  );

  useEffect(() => {
    if (!language && languages?.length) {
      setLanguage(languages[0]);
    }
  }, [languages]);

  if (isLoading) {
    return <PageLoaderWithNav />;
  }

  return (
    <Layout>
      <Tabs value={language} onValueChange={setLanguage}>
        <TabsList>
          {languages.map((l: any, i: number) => {
            const first = i === 0;
            const lang = getLanguage(l);

            return (
              <TabsTrigger
                key={l}
                className="gap-3 flex rounded-lg data-[state=active]:!bg-secondary data-[state=active]:!text-dark"
                value={l}
              >
                <span className="text-sm">{lang?.flag}</span>
                {lang.name}{' '}
                {first && <div className="text-xs flex gap-2 ">{t('audioGuide.detail.tourDefaultLanguage')}</div>}
              </TabsTrigger>
            );
          })}
        </TabsList>

        {languages.map((lang: any) => (
          <TabsContent value={lang} key={lang}>
            <QRCode language={lang} />
          </TabsContent>
        ))}
      </Tabs>
    </Layout>
  );
}
