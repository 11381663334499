import { Layout } from '@/Layout';
import { classNames } from '@/utils';
import { Cog, Goal } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { StationContent } from './StationContent';
import { StationSettings } from './StationSettings';

export function GuideDetail() {
  const [searchParams, setSearchParams] = useSearchParams({ active: 'settings' });
  const { t } = useTranslation('translation');

  return (
    <Layout
      aside={
        <aside className="min-w-[300px] sticky top-16">
          <div className="flex-1 sticky top-16 bg-muted flex">
            <nav className="grid items-start px-4 text-sm font-medium lg:px-4 pt-6 md:pt-10">
              <a
                onClick={() => setSearchParams({ active: 'settings' })}
                className={classNames(
                  'flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary cursor-pointer',
                  searchParams.get('active') === 'settings' && 'font-semibold text-primary',
                )}
              >
                <Cog className="w-5 h-5" /> {t('audioGuide.detail.tourSettings')}
              </a>
              <a
                onClick={() => setSearchParams({ active: 'content' })}
                className={classNames(
                  'flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary cursor-pointer',
                  searchParams.get('active') === 'content' && 'font-semibold text-primary',
                )}
              >
                <Goal className="w-5 h-5" /> {t('audioGuide.detail.stations')}
              </a>
            </nav>
          </div>
        </aside>
      }
    >
      {
        {
          settings: <StationSettings />,
          content: <StationContent />,
        }[searchParams.get('active') as string]
      }
    </Layout>
  );
}
