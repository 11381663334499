import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useTheme } from '@/Theme';
import { Clipboard, DownloadIcon } from 'lucide-react';
import { toString } from 'qrcode';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export function QRCode({ language }: { language: string }) {
  const { t } = useTranslation('translation');
  const theme = useTheme();
  const { shortID, stationID, guideID } = useParams();
  const [qrCode, setQrCode] = useState<string | null>(null);

  const url: string = useMemo(() => {
    if (!language) {
      return '';
    }
    const url = new URL(
      `/${shortID}/${language}/${guideID}`,
      `https://${
        import.meta.env.VITE_ENV === 'stage' ? 'lizzen-audio-guide.cachena.entrecode.de' : 'audioguide.lizzen.de'
      }`,
    );

    if (stationID) {
      url.pathname += `/station/${stationID}`;
    }

    return url.toString();
  }, [language, guideID, shortID, stationID]);

  useEffect(() => {
    if (url) {
      toString(url, {
        margin: 1,
        color: {
          dark: theme === 'guide' ? '#db4a66' : '#00bf9f',
          light: '#0000',
        },
      }).then(setQrCode);
    } else {
      setQrCode(null);
    }
  }, [url, theme]);

  return (
    <div className="my-3 w-full flex gap-6 col-span-2">
      <div className="flex flex-col gap-2">
        {qrCode && <div className="w-52" dangerouslySetInnerHTML={{ __html: qrCode }} />}
        <Button
          variant="secondary"
          className="gap-2"
          onClick={async () => {
            const url = URL.createObjectURL(new Blob([qrCode as string], { type: 'image/svg+xml' }));
            const a = document.createElement('a');
            a.href = url;
            a.download = `QR-Code_${guideID}.svg`;
            a.click();
            URL.revokeObjectURL(url);
          }}
        >
          <DownloadIcon />
          QR-Code speichern
        </Button>
      </div>

      <div className="w-full gap-2 flex flex-col items-start">
        <div>{t('audioGuide.detail.tourShareHeadline')}</div>
        <div className="flex gap-4 w-full">
          <Input readOnly value={url} onClick={({ target }: any) => target.select()} />
          <Button
            onClick={() => {
              navigator.clipboard.writeText(url as string);
              toast.success('Link kopiert');
            }}
          >
            <Clipboard className="w-4 h-4 " />
          </Button>
        </div>

        <Button variant="link" asChild>
          <a href={url} target="_blank" className="text-xs">
            {t('audioGuide.detail.tourShareButton')}
          </a>
        </Button>
      </div>
    </div>
  );
}
