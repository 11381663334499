import { useOidc } from '@axa-fr/react-oidc';
import { createContext, ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';

declare const navigation: any;

const ThemeContext = createContext(null as string | null);

const THEMES = {
  studio: {
    url: import.meta.env.VITE_STUDIO_URL,
    title: 'Lizzen Studio',
    favicon: '/logo.svg',
  },
  guide: {
    url: import.meta.env.VITE_GUIDE_URL,
    title: 'Lizzen Guide',
    favicon: '/logo-guide.svg',
  },
};

export const useTheme = () => {
  const theme = useContext(ThemeContext);
  return theme;
};

export const ThemeSwitcher = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useOidc();
  const [shortID, setShortID] = useState<string | null>(
    new URL(location.href).pathname.split('/').find((p) => /^[0-9-a-f]{8}$/.test(p)) ?? null,
  );
  const [theme, setTheme] = useState<string | null>(
    Object.entries(THEMES).find(([, { url }]) => url === new URL(location.href).origin)?.[0] ?? null,
  );

  const listener = useCallback(({ destination: { url } }) => {
    setShortID(new URL(url).pathname.split('/').find((p) => /^[0-9-a-f]{8}$/.test(p)) ?? null);
  }, []);

  useLayoutEffect(() => {
    navigation.addEventListener('navigate', listener);

    return () => navigation.removeEventListener('navigate', listener);
  }, []);

  useEffect(() => {
    if (shortID) {
      fetch(`https://datamanager${import.meta.env.VITE_ENV === 'stage' ? '.cachena' : ''}.entrecode.de/api/${shortID}`)
        .then((res) => res.json())
        .then(({ config: { type } }) => setTheme(type ?? 'studio'));
    } else {
      setTheme(null);
    }
  }, [shortID]);

  useEffect(() => {
    const root = document.querySelector('html');
    const title = document.querySelector('title');
    const favicon = document.querySelector('link[rel="icon"]');

    if (root) {
      root.setAttribute('data-theme', theme ?? 'studio');
    }

    if (title) {
      title.textContent = THEMES[theme as string]?.title ?? 'Lizzen Studio';
    }

    if (favicon) {
      favicon.setAttribute('href', THEMES[theme as string]?.favicon ?? '/logo.svg');
    }

    if (isAuthenticated) {
      if (!shortID) {
        const url = new URL(location.pathname, import.meta.env.VITE_STUDIO_URL);

        if (location.origin !== url.origin) {
          location.href = url.toString();
        }
      } else if (theme) {
        const url = new URL(location.pathname, THEMES[theme as string]?.url ?? import.meta.env.VITE_STUDIO_URL);
        url.search = location.search;

        if (location.origin !== url.origin) {
          location.href = url.toString();
        }
      }
    }
  }, [theme, shortID, isAuthenticated]);

  return <ThemeContext.Provider value={theme ?? 'studio'}>{shortID && !theme ? null : children}</ThemeContext.Provider>;
};
