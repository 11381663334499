// import { TrashIcon } from '@heroicons/react/24/outline';
// import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../Layout';
import EmptyState from '../components/EmptyState';
// import { showModal } from '../components/Modal';
import { TopNav } from '../components/TopNav';
import { useFdk, usePlainFdk } from '../fdk';
import { ProjectDeleteModal } from './ProjectDetail';
// import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { showModal } from '@/components/Modal';
import { Button } from '@/components/ui/button';
import { DataTableColumnHeader } from '@/components/ui/data-table/data-table-column-header';
import DataTable from '@/components/ui/data-table/data-table-skeleton';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

function Projects() {
  const { shortID } = useParams<{ shortID: string }>();
  const fdk = usePlainFdk();
  const { t } = useTranslation('translation');
  const [selectedProject, setSelectedProject] = useState<any>({});

  const { data: title } = useSWR([shortID], async () => {
    const res = await fetch(
      `https://datamanager${import.meta.env.VITE_ENV === 'stage' ? '.cachena' : ''}.entrecode.de/api/${shortID}`,
    );

    const { title } = await res.json();

    return title;
  });

  const columns: ColumnDef<any>[] = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
        cell: ({ row }) => <div className="max-w-[640px]">{row.getValue('name')}</div>,
      },
      {
        accessorKey: '_created',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Erstellt" />,
        cell: ({ row }) => (
          <div className="max-w-[240px]">
            {dayjs(row.getValue('_created')).format('DD.MM.YYYY HH:mm')} {t('projects.time')}
          </div>
        ),
      },
      {
        accessorKey: 'options',
        header: () => null,
        cell: ({ row: { original } }) => {
          const { t } = useTranslation('translation');

          return (
            <DropdownMenu>
              <DropdownMenuTrigger tabIndex={0} className="h-full flex items-center justify-center w-full">
                <EllipsisHorizontalIcon className="h-4 w-4" />
              </DropdownMenuTrigger>
              <DropdownMenuContent tabIndex={0} className="mt-3 w-52 rounded-box bg-background p-2 shadow-2xl">
                <DropdownMenuItem
                  onClick={async (e) => {
                    e.stopPropagation();
                    const result = await fdk
                      .route(`api/${shortID}/chapter/refcount/project`)
                      .raw({ id: original.id })
                      .catch(() => ({ [original.id]: 0 }));
                    if (result[original.id] > 0) {
                      return toast.error(t('projects.hasChapters'));
                    }
                    setSelectedProject(original);
                    showModal('deleteModal');
                  }}
                >
                  <TrashIcon className="w-4 h-4 mr-2 cursor-pointer" />
                  <span>{t('projects.delete')}</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
      // {
      //   accessorKey: 'description',
      //   header: ({ column }) => <DataTableColumnHeader column={column} title="Beschreibung" />,
      //   cell: ({ row }) => <div className="w-[240px]">{row.getValue('description')}</div>,
      // },
    ];
  }, []);

  const navigation = useNavigate();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchValue, setSearchValue] = useState('');
  const [sort, setSort] = useState<any | null>(null);

  const options = useMemo(() => {
    const options = {
      page: pagination.pageIndex + 1,
      size: pagination.pageSize,
    } as any;
    if (sort) {
      options.sort = `${sort.desc ? '-' : ''}${sort.id}`;
    }
    if (searchValue.length > 2) {
      options['name~'] = searchValue;
    }
    return options;
  }, [searchValue, pagination, sort]);

  const { data: projectList, mutate , isLoading} = useFdk({
    action: 'entryList',
    model: 'project',
    options,
  });

  const projects = useMemo(() => {
    if (!projectList?.items) {
      return;
    }
    if (!Array.isArray(projectList?.items)) {
      return [projectList?.items];
    }
    return projectList?.items;
  }, [projectList]);

  return (
    <Layout>
      <TopNav>
        <div className="flex grow space-x-4">
          <div className="flex-row grow">
            <div className="flex justify-between">
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
                    {title ?? t('projects.project')}
                  </h1>
                </div>
              </div>
              <div className="grid justify-items-end">
                <Button>
                  <Link className="flex" to="create">
                    <PlusIcon className="w-5 h-5" /> {t('projects.newProject')}
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </TopNav>
      <ProjectDeleteModal id="deleteModal" entryID={selectedProject?.id} mutate={mutate} />
      <section className="card col-span-12 overflow-hidden bg-base-100 shadow-sm xl:col-span-10">
        {projects?.length === 0 && searchValue === '' ? (
          <EmptyState
            title={t('projects.emptyState.headline')}
            description={t('projects.emptyState.description')}
            error={t('projects.emptyState.error')}
            onClick={() => (window.location.href = 'projects/create')}
          />
        ) : (
          <DataTable
            pending={isLoading}
            onSort={setSort}
            skeletonColumns={2}
            skeletonRows={5}
            searchValue={searchValue}
            setSearchValue={(x) => {
              setPagination({
                pageIndex: 0,
                pageSize: pagination.pageSize,
              });
              setSearchValue(x);
            }}
            pagination={pagination}
            onRowClick={(row) => {
              navigation(`${row.original.id}`);
            }}
            setPagination={setPagination}
            data={projects || []}
            pageCount={Math.ceil(projectList?.total / pagination?.pageSize)}
            columns={columns}
          />
        )}
      </section>
    </Layout>
  );
}

export default Projects;
