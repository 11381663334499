import { DropZone } from '@/components/FormItems';
import { PageLoaderWithNav } from '@/components/PageLoaderWithNav';
import '@/components/react-tags.css';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Textarea } from '@/components/ui/textarea';
import { useFdk, usePlainFdk } from '@/fdk';
import { getFlagEmoji, languages } from '@/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { QRCode } from './QRCode';
import { TranslateButton } from './TranslateButton';

const formSchema = z.object({
  id: z.string(),
  title: z.record(z.string(), z.string().min(1, { message: 'Titel ist zu kurz' })),
  artist: z.string(),
  audio_files: z.array(z.string()),
  cover: z
    .object({
      assetID: z.string(),
    })
    .or(z.string().or(z.null())),
  chapters: z.array(z.string()),
  audio_map: z.record(
    z.string(),
    z.object({
      type: z.enum(['audio', 'chapter']),
      id: z.string().or(z.undefined()).or(z.null()),
      group: z.string().or(z.undefined()).or(z.null()),
    }),
  ),
  description: z.record(z.string(), z.string()).or(z.any()),
});

export function StationSettings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { stationID, guideID } = useParams();
  const fdk = usePlainFdk();
  const { i18n, t } = useTranslation('translation');
  const currentLang = i18n.language;
  const form = useForm<z.infer<typeof formSchema>>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: {
        de_DE: '',
      },
      cover: '',
      artist: '',
      description: {
        de_DE: '',
      },
      chapters: [],
      audio_map: {
        de_DE: { type: 'chapter' },
      },
    },
  });
  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isDirty, touchedFields },
  } = form;

  const {
    data: station,
    mutate,
    isLoading,
  } = useFdk({
    action: 'getEntry',
    model: 'guide_tour_stations',
    entryID: stationID,
    options: {
      _fields: ['languages', 'duration', 'title', 'description', 'cover', 'chapters', 'audio_map'],
    },
  });

  const { data: tour } = useFdk({
    action: 'getEntry',
    model: 'guide_tours',
    entryID: guideID,
    options: {
      _fields: ['languages'],
    },
  });

  const currentLanguages = useMemo(() => tour?.languages?.split(',') ?? [], [tour]);

  const langs = useMemo(() => languages?.[currentLang] ?? [], [currentLang, languages]);

  const getLanguage = useCallback(
    (lang: string) => {
      const found = langs.find((l) => l.language === lang);

      return {
        language: lang,
        ...(found ?? {}),
        flag: getFlagEmoji(found?.countryCode ?? lang),
      };
    },
    [langs],
  );

  useEffect(() => {
    if (station) {
      reset({
        ...station,
      });
    }
  }, [station]);

  const onSave = useCallback(
    async ({ title, description, cover }: any) => {
      try {
        await fdk.model('guide_tour_stations').editEntry(stationID as string, {
          title,
          description,
          cover: cover?.assetID,
        });
        await mutate();
        toast.success('Station gespeichert');
      } catch (e) {
        console.log(e);
        toast.error('Station konnte nicht gespeichert werden');
      }
    },
    [stationID],
  );

  if (isLoading) {
    return <PageLoaderWithNav />;
  }

  return (
    <Form {...form}>
      <main className="drawer-content px-6 pb-6 flex flex-col gap-6">
        <FormField
          control={form.control}
          name="cover"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t('audioGuide.detail.section.coverImage')}</FormLabel>
              <FormControl>
                <DropZone
                  //@ts-ignore
                  key={field.value?.assetID}
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Tabs defaultValue={searchParams.get('locale') ?? currentLanguages[0]} key={currentLanguages[0]}>
          <TabsList>
            {currentLanguages.map((lang: any, i) => {
              const first = i === 0;
              const { name, flag, language } = getLanguage(lang);

              return (
                <TabsTrigger
                  key={language}
                  className="gap-3 flex rounded-lg data-[state=active]:!bg-secondary data-[state=active]:!text-dark"
                  value={language}
                  onClick={() =>
                    setSearchParams((old) => {
                      old.set('locale', lang);

                      return old;
                    })
                  }
                >
                  <span className="text-sm">{flag}</span>
                  {name}{' '}
                  {first && <div className="text-xs flex gap-2 ">{t('audioGuide.detail.tourDefaultLanguage')}</div>}
                </TabsTrigger>
              );
            })}
          </TabsList>
          {currentLanguages.map((lang, i) => (
            <TabsContent key={lang} value={lang} className="flex flex-col gap-6">
              <FormField
                control={form.control}
                name={`title.${lang}`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel> {t('audioGuide.detail.section.title')} </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`description.${lang}`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel> {t('audioGuide.detail.section.description')} </FormLabel>
                    <FormControl>
                      <Textarea {...(field as any)} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {i !== 0 && (
                <TranslateButton
                  form={form}
                  lang={lang}
                  onTranslate={({ title, description }) => {
                    if (title) {
                      setValue(`title.${lang}`, title);
                    }
                    if (description) {
                      setValue(`description.${lang}`, description);
                    }
                  }}
                />
              )}
              <QRCode language={lang} />
            </TabsContent>
          ))}
        </Tabs>
        <div className="flex items-center justify-end gap-4 sticky bottom-0 py-3">
          {Object.keys(touchedFields || {}).length !== 0 && isDirty && (
            <div className="text-xs text-foreground/45 ">{t('translation:audioGuideDetail.unsavedChanges')}</div>
          )}
           
          <Button onClick={handleSubmit(onSave)} disabled={!isDirty}>
            {t('audioGuide.save')} {isSubmitting && <span className="loading loading-ring loading-sm"></span>}
          </Button>
        </div>
      </main>
    </Form>
  );
}
