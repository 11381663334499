import { Layout } from '@/Layout';
import { DropZone, InputWithOverlay } from '@/components/FormItems';
import { PageLoaderWithNav } from '@/components/PageLoaderWithNav';
import '@/components/react-tags.css';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Textarea } from '@/components/ui/textarea';
import { useFdk, usePlainFdk } from '@/fdk';
import { classNames, getFlagEmoji, languages } from '@/utils';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogClose } from '@radix-ui/react-dialog';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { TranslateButton } from './TranslateButton';

const formSchema = z.object({
  id: z.string(),
  title: z.record(z.string(), z.string().min(1, { message: 'Pflichtfeld' })),
  description: z.record(z.string(), z.string()),
  cover: z
    .object({
      assetID: z.string(),
    })
    .or(z.string().or(z.null())),
  duration: z.string().or(z.number()).or(z.null()),
  languages: z.array(z.string()).min(1, { message: 'Mind. eine Sprache hinzufügen' }),
});

export function GuideSettings() {
  const { guideID } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({
    active: 'desc',
  });

  const fdk = usePlainFdk();
  const navigate = useNavigate();

  const { i18n, t } = useTranslation('translation');
  const currentLang = i18n.language;
  const form = useForm<z.infer<typeof formSchema>>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: {
        de_DE: '',
      },
      description: {
        de_DE: '',
      },
      cover: '',
      duration: '0',
      languages: ['de_DE'],
    },
  });
  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isDirty, touchedFields },
  } = form;

  const {
    data: tour,
    mutate,
    isLoading,
  } = useFdk({
    action: 'getEntry',
    model: 'guide_tours',
    entryID: guideID,
    options: {
      _fields: ['languages', 'duration', 'title', 'description', 'cover'],
    },
  });

  const currentLanguages = watch('languages');

  const langs = useMemo(() => languages?.[currentLang] ?? [], [currentLang, languages]);

  const getLanguage = useCallback(
    (lang: string) => {
      const found = langs.find((l) => l.language === lang);

      return {
        language: lang,
        ...(found ?? {}),
        flag: getFlagEmoji(found?.countryCode ?? lang),
      };
    },
    [langs],
  );

  useEffect(() => {
    if (tour) {
      reset({
        ...tour,
        duration: String(tour.duration),
        languages: tour.languages.split(','),
      });
    }
  }, [tour]);

  const onSave = useCallback(
    async ({ languages, duration, title, description, cover }: any) => {
      try {
        await fdk.model('guide_tours').editEntry(guideID as string, {
          languages: languages.join(','),
          duration: Number.parseInt(duration, 10),
          title,
          description,
          cover: cover?.assetID,
        });
        await mutate();
        toast.success('Tour gespeichert');
      } catch (e) {
        console.log(e);
        toast.error('Tour konnte nicht gespeichert werden');
      }
    },
    [guideID],
  );

  if (isLoading) {
    return <PageLoaderWithNav />;
  }

  return (
    <Form {...form}>
      <Layout
        aside={
          <aside className="min-w-[300px] sticky top-16">
            <nav className="grid items-start px-4 text-sm font-medium lg:px-4 pt-6 md:pt-10">
              <a
                onClick={() => setSearchParams({ active: 'desc' })}
                className={classNames(
                  'flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary cursor-pointer',
                  searchParams.get('active') === 'desc' && 'font-semibold text-primary',
                )}
              >
                {t('audioGuide.detail.titleDescription')}{' '}
                {form.formState.errors.title && <ExclamationTriangleIcon className="w-4 h-4 inline text-destructive" />}
              </a>
              <a
                onClick={() => setSearchParams({ active: 'cover' })}
                className={classNames(
                  'flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary cursor-pointer',
                  searchParams.get('active') === 'cover' && 'font-semibold text-primary',
                )}
              >
                {t('audioGuide.detail.titleCover')}
                {form.formState.errors.cover && <ExclamationTriangleIcon className="w-4 h-4 inline text-destructive" />}
              </a>
              <a
                onClick={() => setSearchParams({ active: 'langs' })}
                className={classNames(
                  'flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary cursor-pointer',
                  searchParams.get('active') === 'langs' && 'font-semibold text-primary',
                )}
              >
                {t('audioGuide.detail.tourLanguages')}{' '}
                {form.formState.errors.languages && (
                  <ExclamationTriangleIcon className="w-4 h-4 inline text-destructive" />
                )}
              </a>
              <a
                onClick={() => setSearchParams({ active: 'delete' })}
                className={classNames(
                  'flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary cursor-pointer',
                  searchParams.get('active') === 'delete' && 'font-semibold text-primary',
                )}
              >
                {t('translation:audioGuide.detail.delete')}
              </a>
            </nav>
          </aside>
        }
      >
        <main className="drawer-content px-6 pb-6">
          <div className="grid w-full">
            {searchParams.get('active') === 'delete' && (
              <div className="max-w-screen-sm">
                <div className="mb-3">{t('translation:audioGuide.detail.deleteModal')}</div>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button className="w-full" variant="destructive">
                      {t('translation:audioGuide.detail.delete')}
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>{t('translation:audioGuide.detail.deleteHeadline')}</DialogTitle>
                    </DialogHeader>
                    <div className="flex gap-3 justify-end">
                      <DialogClose>
                        <Button variant="secondary">Abbrechen</Button>
                      </DialogClose>

                      <Button
                        onClick={async () => {
                          if (guideID) {
                            await fdk.model('guide_tours').deleteEntry(guideID);
                            navigate('..');
                            toast.success(t('translation:audioGuide.detail.tourDeleted'));
                          }
                        }}
                      >
                        {t('translation:audioGuide.detail.delete')}
                      </Button>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            )}
            {searchParams.get('active') === 'langs' && (
              <div className="flex flex-col gap-6">
                <FormField
                  control={form.control}
                  name="languages"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('audioGuide.detail.tourLanguages')}</FormLabel>
                      <FormControl>
                        <div className="flex">
                          <div className="flex flex-wrap gap-2">
                            {field.value.map((e) => {
                              const { name, language } = getLanguage(e);
                              return (
                                <Badge key={language} variant="outline" className="mr-1 flex gap-0.5 select-none">
                                  {name}
                                  <XMarkIcon
                                    onClick={(ev: any) => {
                                      ev.stopPropagation();
                                      field.onChange(field.value.filter((v) => v !== e));
                                    }}
                                    className="cursor-pointer ml-2 w-4 h-4 hover:text-red-400"
                                  />
                                </Badge>
                              );
                            })}
                          </div>
                          <Select
                            key={field.value.length}
                            onValueChange={(value) => field.onChange([...field.value, value])}
                          >
                            <SelectTrigger className="w-[180px]">
                              <SelectValue placeholder={t('audioGuide.detail.tourLanguagesAdd')} />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {langs
                                  .filter(({ language }) => !currentLanguages.includes(language))
                                  .map(({ name, language }) => (
                                    <SelectItem key={name} value={language}>
                                      {name}
                                    </SelectItem>
                                  ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="languages"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('audioGuide.detail.tourDefaultLanguage')}</FormLabel>
                      <FormControl>
                        <Select
                          value={field.value[0] as any}
                          onValueChange={(value) => {
                            const newArray = field.value.filter((v) => v !== (value as any));
                            newArray.unshift(value as any);
                            field.onChange(newArray);
                          }}
                        >
                          <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Sprache hinzufügen" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {field.value.map((lang) => {
                                const { name, language } = getLanguage(lang);
                                return (
                                  <SelectItem key={name} value={language}>
                                    {name}
                                  </SelectItem>
                                );
                              })}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormDescription>{t('audioGuide.detail.tourDefaultLanguageDescription')}</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}
            {searchParams.get('active') === 'cover' && (
              <FormField
                control={form.control}
                name="cover"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel> {t('audioGuide.detail.titleCover')}</FormLabel>
                    <FormControl>
                      <DropZone
                        //@ts-ignore
                        key={field.value?.assetID}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            {searchParams.get('active') === 'desc' && (
              <div className="w-full">
                <div className="flex flex-col gap-6">
                  <Tabs defaultValue={searchParams.get('locale') ?? currentLanguages[0]} key={currentLanguages[0]}>
                    <TabsList>
                      {currentLanguages.map((lang: any, i) => {
                        const first = i === 0;
                        const { name, flag, language } = getLanguage(lang);

                        return (
                          <TabsTrigger
                            key={language}
                            className="gap-3 flex rounded-lg data-[state=active]:!bg-secondary data-[state=active]:!text-dark"
                            value={language}
                            onClick={() =>
                              setSearchParams((old) => {
                                old.set('locale', lang);

                                return old;
                              })
                            }
                          >
                            <span className="text-sm">{flag}</span>
                            {name}{' '}
                            {first && (
                              <div className="text-xs flex gap-2 ">{t('audioGuide.detail.tourDefaultLanguage')}</div>
                            )}
                          </TabsTrigger>
                        );
                      })}
                    </TabsList>
                    {currentLanguages.map((lang, i) => (
                      <TabsContent key={lang} value={lang} className="flex flex-col gap-6">
                        <FormField
                          control={form.control}
                          name={`title.${lang}`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel> {t('audioGuide.detail.title')} </FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name={`description.${lang}`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel> {t('audioGuide.detail.description')} </FormLabel>
                              <FormControl>
                                <Textarea {...(field as any)} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        {i !== 0 && (
                          <TranslateButton
                            form={form}
                            lang={lang}
                            defaultLang={currentLanguages[0]}
                            onTranslate={({ title, description }) => {
                              if (title) {
                                setValue(`title.${lang}`, title);
                              }
                              if (description) {
                                setValue(`description.${lang}`, description);
                              }
                            }}
                          />
                        )}
                      </TabsContent>
                    ))}
                  </Tabs>
                  <FormField
                    control={form.control}
                    name="duration"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel> {t('audioGuide.detail.duration')} </FormLabel>
                        <FormControl>
                          <InputWithOverlay {...(field as any)} />
                        </FormControl>
                        <FormDescription>{t('audioGuide.detail.durationDescription')}</FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center justify-end gap-4 sticky bottom-0 py-3">
            {Object.keys(touchedFields || {}).length !== 0 && isDirty && (
              <div className="text-xs text-foreground/45 ">{t('translation:audioGuideDetail.unsavedChanges')}</div>
            )}
             
            {searchParams.get('active') !== 'delete' && (
              <Button onClick={handleSubmit(onSave)} disabled={!isDirty}>
                {t('audioGuide.save')} {isSubmitting && <span className="loading loading-ring loading-sm"></span>}
              </Button>
            )}
          </div>
        </main>
      </Layout>
    </Form>
  );
}
