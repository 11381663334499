import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AILoader from '../components/AILoader';
import { useStudioApi } from '../hooks/useStudioAPI';
import { assistants, classNames } from '../utils';

export function ProjectCreate({ chapter }: { chapter?: boolean }) {
  const navigate = useNavigate();
  const { shortID, projectID } = useParams();
  const [pending, setPending] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedAssistant, setSelectedAssistant] = useState<any>('empty');
  const [name, setName] = useState<any>(searchParams.get('name'));
  const { watch, setValue, register, formState, handleSubmit, reset, trigger } = useForm({ mode: 'all' });
  const [changes, setChanges] = useState<any>({});
  const [step, setStep] = useState(0);
  const { t, i18n } = useTranslation('translation');
  const { data: voices } = useStudioApi('voices');

  const type = useMemo(
    () => assistants[i18n.language]?.find(({ type }) => type === searchParams.get('type')),
    [searchParams],
  );
  const id = useMemo(() => searchParams.get('id'), [searchParams]);
  const couldNext = useMemo(() => {
    const keys = type?.elements?.[step]?.map(({ key }: any) => key).filter(Boolean);

    return keys?.every((key) => !formState.errors[key]);
  }, [step, type, formState.errors]);

  const { data: preview, isValidating, mutate } = useStudioApi(id ? `generate/${id}` : null);

  useEffect(() => {
    if (preview?.inputs) {
      reset(preview.inputs);
    }
  }, [preview]);

  useEffect(() => {
    trigger();
  }, [step]);

  const voiceID = useMemo(() => {
    if (!voices?.length) {
      return null;
    }
    if (voices?.includes(({ id }) => id === import.meta.env.VITE_DEFAULT_VOICE_ID)) {
      return import.meta.env.VITE_DEFAULT_VOICE_ID;
    }

    return voices[0]?.id ?? null;
  }, [voices]);

  const submit = useCallback(
    async (data: any) =>
      fetchEventSource(`${import.meta.env.VITE_QUEUE_URL}/${shortID}/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          await Object.entries(data).reduce(
            async (acc, [key, value]: any) => {
              acc = await acc;

              if (value === null) {
                delete acc[key];
              } else if (value instanceof FileList) {
                acc[key] = await new Promise((resolve) => {
                  const reader = new FileReader();
                  reader.onload = () => resolve(reader.result);
                  reader.readAsDataURL(value[0]);
                });
              } else {
                acc[key] = value;
              }

              return acc;
            },
            Promise.resolve({
              name: data?.name ?? searchParams.get('name'),
              type: data?.type ?? searchParams.get('type'),
              voiceID,
              projectID,
            } as any),
          ),
        ),
        onmessage({ data, event }) {
          if (event === 'started') {
            setPending(true);
          }
          if (event === 'completed') {
            const { id, chapter: c } = JSON.parse(data);
            toast.success(t('projects.projectCreate.previewCreatedSuccess'));
            if (searchParams.get('type') === 'podcast') {
              setSearchParams({ id, type: searchParams.get('type'), name: searchParams.get('name') } as any);
              setChanges({});
            } else {
              if (c) {
                if (chapter) {
                  navigate(`../chapters/${c}`);
                } else {
                  navigate(`../${id}/chapters/${c}`);
                }
              } else {
                navigate(`../${id}`);
              }
            }
          }
        },
        async onclose() {
          setPending(false);
        },
        onerror() {
          toast.error(t('projects.projectCreate.previewCreatedError'));
          throw new Error('no_retry');
        },
        openWhenHidden: true,
      }).catch(() => null),
    [shortID, voiceID, projectID, searchParams],
  );

  const createData = useCallback(async () => {
    try {
      setPending(true);
      if (Object.keys(changes).length) {
        const res = await fetch(`${import.meta.env.VITE_API_URL}/${shortID}/generate/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(changes),
        });
        if (!res.ok) {
          throw new Error(res.statusText);
        }

        const json = await res.json();
        await mutate(json, false);
      }

      const res = await fetch(`${import.meta.env.VITE_API_URL}/${shortID}/generate/${id}`, {
        method: 'POST',
      });
      if (!res.ok) {
        throw new Error(res.statusText);
      }

      const json = await res.json();
      navigate(`../${json.id}`);
      toast.success(t('projects.projectCreate.creatingDataSuccess'));
    } catch (err) {
      toast.error(t('projects.projectCreate.creatingDataError'));
    } finally {
      setPending(false);
    }
  }, [shortID, changes, id]);

  return (
    <main className="drawer-content flex items-center justify-center min-h-screen w-screen">
      {isValidating && (
        <div className="flex justify-center items-center fixed w-screen h-screen top-0 left-0 right-0 bottom-0 saturate-50 bg-base-100 bg-opacity-60 z-10">
          <span className="loading loading-infinity loading-lg"></span>
        </div>
      )}
      <Fragment>
        {pending && <AILoader type={type?.title} chapter />}
        {!id && !pending && (
          <Fragment>
            {!type && (
              <div className="space-y-24">
                <div className="space-y-6 text-center">
                  <h3 className="text-2xl">
                    {t(chapter ? 'projects.chapterCreate.headline' : 'projects.projectCreate.headline')}
                  </h3>
                  <div className="form-control">
                    <Input
                      required
                      autoComplete="off"
                      type="text"
                      defaultValue={name}
                      autoFocus
                      onChange={(e) => setName(e.target.value)}
                      placeholder={t('projects.projectCreate.namePlaceholder')}
                      name="name"
                    />
                  </div>
                </div>
                <div className="space-y-6 text-center">
                  <h3 className="text-2xl">
                    {t(chapter ? 'projects.chapterCreate.chooseTemplate' : 'projects.projectCreate.chooseTemplate')}
                  </h3>
                  <div className="grid sm:grid-cols-2 gap-6">
                    {(assistants[i18n.language] || assistants['de'])
                      ?.filter((assistant) => {
                        if (chapter) {
                          if (!assistant.chapter) {
                            return false;
                          }
                        }
                        return !assistant.disabled;
                      })
                      ?.map((assistant) => (
                        <button
                          key={assistant.type}
                          className={classNames(
                            `relative rounded-md ring-1`,
                            selectedAssistant === assistant.type ? 'ring-primary bg-primary/5' : 'ring-border',
                          )}
                          onClick={() => setSelectedAssistant(assistant.type)}
                        >
                          <div
                            key={assistant.type}
                            className={classNames(
                              'bg-base-200 rounded-lg text-left',
                              assistant.disabled && 'pointer-events-none opacity-35 saturate-50 cursor-not-allowed',
                            )}
                          >
                            <div className="flex items-center space-x-2 justify-between">
                              {assistant.icon && <assistant.icon className="w-8 h-8 m-4" />}
                              <div className="w-40 leading-tight my-4 !mr-4">
                                <h4>{assistant.title}</h4>
                                <small className="font-light text-gray-400">{assistant.description}</small>
                              </div>
                            </div>
                          </div>
                        </button>
                      ))}
                  </div>
                </div>
                <div className="flex justify-center">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger
                        asChild
                        className={classNames((!name?.length || !selectedAssistant) && 'cursor-not-allowed')}
                      >
                        <Button
                          disabled={!name?.length || !selectedAssistant || !voiceID}
                          className={classNames((!name?.length || !selectedAssistant) && 'cursor-not-allowed')}
                          type="button"
                          onClick={async () => {
                            if (selectedAssistant === 'stt') {
                              navigate(`../create-stt?name=${name}`); // sorry not sorry :D
                              return;
                            }
                            const { elements } = assistants[i18n.language].find(
                              ({ type }) => type === selectedAssistant,
                            );

                            if (!elements?.length) {
                              await submit({
                                type: selectedAssistant,
                                name,
                              });
                            }
                            setSearchParams({ type: selectedAssistant, name } as any);
                          }}
                        >
                          Jetzt starten
                        </Button>
                      </TooltipTrigger>
                      {!name && (
                        <TooltipContent>
                          {t(
                            chapter
                              ? 'projects.chapterCreate.enterNameTooltip'
                              : 'projects.projectCreate.enterNameTooltip',
                          )}
                        </TooltipContent>
                      )}
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            )}
            {type && type !== 'empty' && (
              <Fragment>
                <div className="flex items-center fixed top-0 left-0 p-4">
                  <Button
                    onClick={() => {
                      setStep(0);
                      reset();
                      setSearchParams({});
                    }}
                    variant="link"
                  >
                    <ArrowLeftIcon className="w-4 h-4" />
                  </Button>
                  <h1 className="text-xl">{t('translation:projects.create.breadcrumb', { type: type.title })}</h1>
                </div>
                <div className="w-full lg:w-[40vw] mx-auto px-8 text-center flex flex-col space-y-10">
                  {type.elements?.map((e, i) => {
                    if (i !== step) {
                      return null;
                    }

                    return (
                      <div key={i} className="space-y-3">
                        {e.map((el) => (
                          <div key={el.key + i} className="form-control">
                            {el.type === 'h1' ? (
                              <h3 className="text-2xl">{el.content}</h3>
                            ) : (
                              <>
                                <Label className="label flex justify-between mb-3">
                                  <span className="label-text whitespace-normal sm:whitespace-nowrap">{el.label}</span>
                                  {el.description && (
                                    <span className="ml-6 label-text-alt text-gray-500 text-right text-xs">
                                      {el.description}
                                    </span>
                                  )}
                                </Label>
                                {['file', 'input'].includes(el.type) ? (
                                  <Input
                                    {...register(el.key, { required: el.required })}
                                    autoFocus={i === step}
                                    autoComplete="off"
                                    type={el.type === 'file' ? 'file' : 'text'}
                                    accept={el.accept}
                                    placeholder={el.placeholder}
                                  />
                                ) : el.type === 'textarea' ? (
                                  <Textarea
                                    {...register(el.key, { required: el.required })}
                                    autoFocus={i === step}
                                    autoComplete="off"
                                    rows={5}
                                    placeholder={el.placeholder}
                                  />
                                ) : el.type === 'select' ? (
                                  <Select
                                    //todo: fix this
                                    defaultValue={watch(el.key)}
                                    onValueChange={(e) => setValue(el.key, e)}
                                    autoComplete="off"
                                  >
                                    <SelectTrigger>
                                      <span className="block truncate">
                                        {watch(el.key) ? watch(el.key) : t('translation:projects.create.selectLabel')}
                                      </span>
                                    </SelectTrigger>
                                    <SelectContent>
                                      <SelectGroup>
                                        {el.options.map((opt) => (
                                          <SelectItem key={opt.value} value={opt.value}>
                                            <div className="flex gap-x-2">{opt.label}</div>
                                          </SelectItem>
                                        ))}
                                      </SelectGroup>
                                    </SelectContent>
                                  </Select>
                                ) : null}
                              </>
                            )}
                            {el.info && <p className="mt-3 text-xs leading-2 text-gray-600 text-left">{el.info}</p>}
                          </div>
                        ))}
                      </div>
                    );
                  })}
                  <div className="flex justify-end gap-x-2 mt-20">
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => setStep((old) => Math.max(0, old - 1))}
                      disabled={step === 0}
                    >
                      Zurück
                    </Button>

                    <div className="text-right pb-20">
                      <Button
                        type="button"
                        disabled={!couldNext || pending || !formState.isValid || formState.isSubmitting}
                        onClick={async () => {
                          const res = await trigger();
                          if (res) {
                            if (step !== (type?.elements?.length ?? 0) - 1) {
                              setStep((old) => old + 1);
                            } else {
                              await handleSubmit(submit)();
                            }
                          }
                        }}
                      >
                        {step !== (type?.elements?.length ?? 0) - 1 ? 'Weiter' : type?.button}
                      </Button>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
        {preview && !pending && (
          <div className="space-y-12 min-w-[40vw] mx-auto p-8">
            <div className="space-y-6 text-center">
              <h3 className="text-2xl">{t('translation:projects.create.previewHeadline', { type: type?.title })}</h3>
              <div className="form-control">
                <Label className="label">
                  <span className="label-text">Titel des {type?.title}s</span>
                  {/*
                  <span className="label-text-alt text-gray-500">
                    <select className="select select-sm select-bordered -mr-1">
                      <option>Titel bearbeiten</option>
                    </select>
                  </span>
                  */}
                </Label>
                <Input
                  required
                  autoComplete="off"
                  type="text"
                  defaultValue={preview.name}
                  onChange={(e) => setChanges((old) => ({ ...old, name: e.target.value }))}
                  placeholder="Name eingeben..."
                  className="input input-bordered"
                  name="name"
                />
              </div>
              <div className="form-control">
                <Label className="label">
                  <span className="label-text">Beschreibung des {type?.title}s</span>
                  {/*
                  <span className="label-text-alt text-gray-500">
                    <select className="select select-sm select-bordered -mr-1">
                      <option>Beschreibung bearbeiten</option>
                    </select>
                  </span>
                  */}
                </Label>
                <Textarea
                  required
                  autoComplete="off"
                  defaultValue={preview.description}
                  onChange={(e) => setChanges((old) => ({ ...old, description: e.target.value }))}
                  placeholder={t('projects.projectCreate.descriptionPlaceholder')}
                  className="textarea textarea-bordered"
                  name="name"
                />
              </div>
            </div>

            <div className="space-y-6 text-center">
              <h3 className="text-2xl">Vorschau deiner Episoden</h3>
              {preview.items?.map((item, index) => (
                <div className="form-control" key={index}>
                  <Label className="label gap-2">
                    <span className="label-text content-normal">
                      <Input
                        required
                        autoComplete="off"
                        type="text"
                        defaultValue={item.name}
                        onChange={(e) =>
                          setChanges((old) => {
                            return {
                              ...old,
                              items: preview.items.map((_i, iIndex) => {
                                if (iIndex === index) {
                                  return { ...(old.items?.[index] ?? {}), name: e.target.value };
                                }
                                return old.items?.[iIndex] ?? null;
                              }),
                            };
                          })
                        }
                        placeholder={t('projects.projectCreate.namePlaceholder')}
                        className="input input-sm input-bordered"
                        name="name"
                      />
                    </span>
                    <span className="label-text grow">
                      <Input
                        required
                        autoComplete="off"
                        type="text"
                        defaultValue={item.summary}
                        onChange={(e) =>
                          setChanges((old) => {
                            return {
                              ...old,
                              items: preview.items.map((_i, iIndex) => {
                                if (iIndex === index) {
                                  return { ...(old.items?.[index] ?? {}), summary: e.target.value };
                                }
                                return old.items?.[iIndex] ?? null;
                              }),
                            };
                          })
                        }
                        placeholder={t('projects.projectCreate.summaryPlaceholder')}
                        className="input input-sm input-bordered"
                        name="summary"
                      />
                    </span>
                    {/*
                    <span className="label-text-alt text-gray-500">
                      <select
                        className="select select-sm select-bordered"
                        onChange={(e) =>
                          setChanges((old) => {
                            return {
                              ...old,
                              items: preview.items.map((_i, iIndex) => {
                                if (iIndex === index) {
                                  return { ...(old.items?.[index] ?? {}), action: e.target.value };
                                }
                                return old.items?.[iIndex] ?? null;
                              }),
                            };
                          })
                        }
                      >
                        <option value="edit">Episode bearbeiten</option>
                        <option value="regenerate">Episode neu generieren</option>
                        <option value="funnier">Etwas lustiger</option>
                        <option value="delete">Episode löschen</option>
                      </select>
                    </span>
                  */}
                  </Label>
                  <Textarea
                    required
                    autoComplete="off"
                    defaultValue={item.content}
                    onChange={(e) =>
                      setChanges((old) => {
                        return {
                          ...old,
                          items: preview.items.map((_i, iIndex) => {
                            if (iIndex === index) {
                              return { ...(old.items?.[index] ?? {}), content: e.target.value };
                            }
                            return old.items?.[iIndex] ?? null;
                          }),
                        };
                      })
                    }
                    placeholder={t('projects.projectCreate.descriptionPlaceholder')}
                    className="textarea textarea-bordered"
                    name="content"
                  />
                </div>
              ))}
            </div>
            <div className="flex justify-end gap-x-2 pb-20">
              <Button className="btn btn-primary btn-sm" type="button" onClick={createData} disabled={pending}>
                {t('projects.projectCreate.saveAndContinue')}
              </Button>
            </div>
          </div>
        )}
      </Fragment>
    </main>
  );
}
