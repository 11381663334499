import Button from '@/components/Button';
import { languages } from '@/utils';
import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export function TranslateButton({
  form,
  lang,
  defaultLang = 'de_DE',
  station,
  onTranslate,
}: {
  form: any;
  lang: string;
  defaultLang?: string;
  station?: string;
  onTranslate: (content: any) => void;
}) {
  const { shortID, guideID }: any = useParams();
  const { i18n } = useTranslation('translation');
  const [pending, setPending] = useState(false);
  const currentLang = i18n.language;

  const defaultLocale = useMemo(() => {
    return languages[currentLang].find(({ language }) => language === defaultLang)?.name ?? 'Deutsch';
  }, [currentLang, defaultLang]);

  const translate = useCallback(async () => {
    try {
      setPending(true);
      const url = new URL(
        station
          ? `/${shortID}/translate/audio-guide/station/${station}`
          : `/${shortID}/translate/audio-guide/${guideID}`,
        import.meta.env.VITE_API_URL,
      );

      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          translateTo: lang,
        }),
      });
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const { title, description } = await res.json();

      onTranslate({
        title,
        description,
      });
    } catch (err) {
      throw err;
    } finally {
      setPending(false);
    }
  }, [lang]);

  return (
    <Button
      variant="outline"
      onClick={() =>
        toast.promise(translate(), {
          loading: 'Übersetze...',
          success: 'Erfolgreich übersetzt',
          error: 'Fehler beim Übersetzen',
        })
      }
      disabled={pending}
    >
      automatisch aus {defaultLocale} übersetzen
    </Button>
  );
}
