import AudioRecorder from '@/components/Editor/AudioRecorder';
import { Player } from '@/components/Editor/Section';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { DocumentPlusIcon, MicrophoneIcon } from '@heroicons/react/24/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { hideModal } from '../components/Modal';
import { useStudioApi } from '../hooks/useStudioAPI';
import { TAGS } from '../utils';

export function VoiceCreateModal({ id, onCreate }: { id: string; onCreate: () => void }) {
  const { shortID } = useParams();
  const form = useRef<HTMLFormElement>(null);
  // const audio = useRef<HTMLInputElement>(null);
  const [pending, setPending] = useState(false);
  const { data: tags } = useStudioApi('tags');
  const { t, i18n } = useTranslation('translation');

  const formSchema = z.object({
    name: z.string().min(1, { message: t('voices.voiceCreateModal.required') }),
    description: z.string().min(1, { message: t('voices.voiceCreateModal.required') }),
    file: z.any(),
    fileType: z.enum(['file', 'url']),
    // file: z.any(),
    tags: z.record(z.string(), z.string()),
    terms: z.boolean().refine((v) => v === true, { message: t('voices.voiceCreateModal.required') }),
  });

  const localTags = useMemo(
    () =>
      // TODO: groupBy is not supported by Safari
      // Object.groupBy(tags?.filter(({ global }) => !global) ?? [], ({ type }) => type),
      (tags?.filter(({ global }) => !global) ?? []).reduce((acc, cur) => {
        if (!acc[cur.type]) {
          acc[cur.type] = [];
        }
        acc[cur.type].push(cur);
        return acc;
      }, {}),
    [tags],
  );

  const f = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      file: '',
      fileType: 'file',
      description: '',
      name: '',
      tags: {},
      terms: false,
    },
  });

  return (
    <>
      <h3 className="font-bold text-lg">{t('voices.voiceCreateModal.headline')}</h3>
      <Form {...f}>
        <form
          ref={form}
          className="flex flex-col gap-y-3 group"
          onSubmit={f.handleSubmit(async (values) => {
            try {
              setPending(true);
              // const formData = new FormData(e.target);
              const { description, name } = values;

              // const files = audio.current?.files?.[0];
              // console.log(files);

              const body = new FormData();
              body.append('file', values.file as Blob);
              body.append('name', name);
              body.append('description', description);

              const res = await fetch(`${import.meta.env.VITE_API_URL}/${shortID}/voices`, {
                method: 'POST',
                body,
              });

              if (!res.ok) {
                throw new Error(t('voices.voiceCreateModal.createError'));
              }

              await onCreate();
              hideModal('createModal');
              toast.success(t('voices.voiceCreateModal.createSuccess'));
            } catch ({ message }: any) {
              toast.error(message);
            } finally {
              setPending(false);
            }
          })}
        >
          <FormField
            control={f.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('voices.voiceCreateModal.name')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={f.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('voices.voiceCreateModal.description')}</FormLabel>
                <FormControl>
                  <Textarea {...field} placeholder={t('voices.voiceCreateModal.descriptionPlaceholder')} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {Object.entries(localTags).map(([type, tags]: any) => (
            <div key={type} className="form-control">
              <label className="label">
                <span className="label-text">{TAGS[i18n.language][type]}</span>
              </label>
              <select autoComplete="off" className="select select-bordered" name={`tags.${type}`} defaultValue={-1}>
                <option value={-1} disabled hidden>
                  {t('voices.voiceCreateModal.chooseTag', { type: TAGS[i18n.language][type] })}
                </option>
                {tags.map((tag: any) => (
                  <option key={tag.id} value={tag.id}>
                    {tag.label}
                  </option>
                ))}
              </select>
            </div>
          ))}

          <div className="py-6">
            <FormAudio f={f} />
          </div>

          {/* <FormField
            control={f.control}
            name="file"
            render={({ field }) => (
              <>
                <FormLabel>{t('voices.voiceCreateModal.uploadFile')}</FormLabel>
                <FormControl>
                  <label className="block">
                    <input {...field} type="file" className="hidden peer" accept="audio/mpeg, audio/wav" ref={audio} onChange={(e) => {console.log('e', e.target.files?.[0]);
                      }} />
                    {field.value ? (
                      <Card>
                        <CardContent className="flex p-2 items-center justify-between">
                          {field.value.split(/(\\|\/)/g).pop()}
                          <XMarkIcon
                            className="w-4 h-4 text-destructive cursor-pointer"
                            onClick={() => field.onChange('')}
                          />
                        </CardContent>
                      </Card>
                    ) : (
                      <div className="flex items-center justify-center border p-6 rounded-md border-dashed gap-x-4 input-bordered cursor-pointer peer-valid:bg-base-300 peer-valid:text-success">
                        <ArrowUpTrayIcon className="w-6 h-6 opacity-80" />
                        <div className="text-center opacity-80 hyphens-auto">
                          {t('voices.voiceCreateModal.audioFile')}
                          <br />
                          {t('voices.voiceCreateModal.maxSize')}
                        </div>
                      </div>
                    )}
                  </label>
                </FormControl>
                <FormMessage />
              </>
            )}
          /> */}

          <FormField
            control={f.control}
            name="terms"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('voices.voiceCreateModal.legalHeadline')}</FormLabel>
                <FormControl>
                  <label className="flex gap-3">
                    <Checkbox
                      ref={field.ref}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      name={field.name}
                      onBlur={field.onBlur}
                    />
                    <div className="label-text text-xs">{t('voices.voiceCreateModal.legalText')}</div>
                  </label>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="form-control">
            <div className="flex justify-end pt-4 gap-x-4">
              <Button
                variant="outline"
                disabled={pending}
                onClick={(e) => {
                  e.preventDefault();
                  onCreate();
                  f.reset();
                }}
              >
                {t('voices.voiceCreateModal.cancel')}
              </Button>
              <Button disabled={pending}>
                {t('voices.voiceCreateModal.save')}{' '}
                {pending && <span className="loading loading-infinity loading-xs" />}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </>
  );
}

export const FormAudio = ({ f, description, maxSize }: { f: any; description?: string; maxSize?: number }) => {
  const { t } = useTranslation('translation');
  const [recordAudio, setRecordAudio] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const inputRef = useRef<any>(null);
  const { watch, setValue } = f;

  return watch('file') === '' ? (
    <div
      className="flex flex-col items-center justify-center gap-y-2 w-full h-full cursor-pointer"
      onClick={() => {
        inputRef.current.click();
      }}
    >
      <input
        type="file"
        ref={inputRef}
        accept=".mp3"
        className="hidden file-input file-input-bordered file-input-primary bg-base-200 w-full max-w-xs h-8 rounded-md"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file && maxSize && file.size / (1024 * 1024) > maxSize) {
            toast.error(`Die Datei darf nicht größer als ${maxSize}MB sein`);
          } else {
            setValue(`file`, e.target.files?.[0] ?? ('' as any), { shouldDirty: true });
          }
        }}
      />
      {!recordAudio ? (
        <>
          <DocumentPlusIcon className="w-12 h-12 text-primary/50" />
          <h3 className="mt-2 text-sm font-semibold text-gray-100">{t('chapter.sectionSpeech.uploadFile')}</h3>
          <p className="mt-1 text-sm text-gray-200">{description || t('chapter.sectionSpeech.selectFile')}</p>
          <div className="w-4/12 border-b border-border relative flex justify-center my-4">
            <span className="absolute p-2 bg-muted -top-5 margin-auto rounded-md">{t('chapter.sectionSpeech.or')}</span>
          </div>

          <div className="mt-2">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setRecordAudio(true);
              }}
              className="inline-flex items-center"
            >
              <MicrophoneIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              {t('chapter.sectionSpeech.recordAudio')}
            </Button>
          </div>
        </>
      ) : (
        <AudioRecorder setValue={setValue} audioRef={audioRef} />
      )}
    </div>
  ) : (
    <Player
      url={watch('fileType') === 'url' ? watch('file') : URL.createObjectURL(watch('file') as any)}
      onDelete={() => {
        setRecordAudio(false);
        setValue('file', '');
      }}
      onUpload={() => {}}
      showUpload={false}
    />
  );
};
