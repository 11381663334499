import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { fetcher } from 'ec.fdk';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

export function LimitAlert() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState<boolean>(false);
  const { shortID } = useParams();
  const { data: abo } = useSWR(shortID && `${import.meta.env.VITE_API_URL}/${shortID}/abo`, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    const isInRoute = ['/settings', '/settings/abo-manage'].some((p) => location.pathname.endsWith(p));
    if (abo?.usage?.limitReached && !isInRoute) {
      setOpen(true);
    }
  }, [abo, location.pathname]);

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('limitAlert.headline')}</AlertDialogTitle>
          <AlertDialogDescription>{t('limitAlert.message')}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{t('limitAlert.hide')}</AlertDialogCancel>
          <AlertDialogAction variant="secondary" onClick={() => navigate('settings/abo-manage')}>
            {t('limitAlert.aboUpgrade')}
          </AlertDialogAction>
          <AlertDialogAction onClick={() => navigate('settings')}>{t('limitAlert.editLimit')}</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
