import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePlainFdk } from '../../fdk';
import Loader from '../Loader';
import { Button } from '../ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Label } from '../ui/label';
dayjs.extend(duration);

export default function ChapterErrorModal({ mutate, chapter: { id: chapterID } }) {
  const { t } = useTranslation('translation');
  const fdk = usePlainFdk();
  const [pending, setPending] = useState(false);

  const { watch } = useFormContext();

  return (
    <Dialog open={watch('chapter.state') === 'errored'}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('chapter.chapterErrorModal.headline')}</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col">
          <div>
            <Label>{t('chapter.chapterErrorModal.description')}</Label>
          </div>
        </div>
        <div className="flex items-end justify-end py-4 gap-x-2">
          <Button
            variant="secondary"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {t('chapter.chapterErrorModal.cancelButtonText')}
          </Button>
          <Button
            className="gap-x-2"
            onClick={async () => {
              setPending(true);
              const entry = await fdk.model('chapter').editEntry(chapterID as string, { state: 'draft' });
              await mutate(entry, { revalidate: false });
              setPending(false);
            }}
          >
            {pending && <Loader size={3} textColor="text-foreground" fillColor="fill-background" />}
            {t('chapter.chapterErrorModal.resetStateButtonText')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
